import React, { useState, createContext, useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import { useTranslation } from "react-i18next";
import setErrorPage from "../utils/errorPage";
import { useNavigate } from "react-router-dom";

const CardsContext = createContext();

export default CardsContext;

export const CardsProvider = ({ children }) => {
    const navigate = useNavigate();
    const { authTokens, user, setCredentials } = useContext(AuthContext);
    const { t } = useTranslation();
    const [loadingPage, setLoadingPage] = useState(true);
    const [cards, setCards] = useState([]);
    const [sections, setSections] = useState([]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        if (user) {
            getCategories();
        }
    }, [user]);
    async function mes() {
        const intervalId = setInterval(() => {
            document.getElementById("loading-page").style.display = "grid";
        }, 700);
        return intervalId;
    }

    async function getCards(intervalId) {
        const response = await fetch("/api/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            data.sort((a, b) => {
                return new Date(a.created_time) - new Date(b.created_time);
            });
            setCards(data);
            setLoadingPage(false);
            document.getElementById("loading-page").style.display = "none";
        } else if (response.status === 202) {
            setCards("");
            setLoadingPage(false);
            document.getElementById("loading-page").style.display = "none";
            navigate("/pay");
        } else if (response.status === 301) {
            setCards("");
            setCredentials(data);
            setLoadingPage(false);
            document.getElementById("loading-page").style.display = "none";
            navigate("/pay");
        } else {
            setErrorPage();
        }
        if (intervalId) {
            clearInterval(intervalId);
        }
    }

    async function getCategories() {
        const intervalId = await mes();
        const response = await fetch("/api/categories/", {
            method: "GET",
            headers: {
                "Content-Type": "application-json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            setCategories(data);
            getSections(intervalId);
        } else if (response.status === 202) {
            setCategories("");
            getSections(intervalId);
            navigate("/pay");
        } else {
            setErrorPage();
        }
    }
    async function getSections(intervalId) {
        const response = await fetch("/api/sections/", {
            method: "GET",
            headers: {
                "Content-Type": "application-json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            const known = data.splice(1, 1);
            setSections(data.concat(known));
            getCards(intervalId);
        } else if (response.status === 202) {
            setSections("");
            getCards(intervalId);
            navigate("/pay");
        } else {
            setErrorPage();
        }
    }

    const contextData = {
        cards: cards,
        allSections: sections,
        allCategories: categories,
        getCards: getCards,
        getSections: getSections,
        getCategories: getCategories,
        loadingPage: loadingPage,
    };

    const createContent = () => {
        if (loadingPage && !user && window.location.href.slice(-2) === "#/") {
            return <h1>{t("Login to use the app")}</h1>;
        } else if (!loadingPage && !user) {
            return null;
        } else if (!loadingPage && user) {
            return children;
        } else if (loadingPage && !user) {
            return children;
            // return <h1>{t("Login to use the app")}</h1>;
        }
    };
    const content = createContent();
    return (
        <CardsContext.Provider value={contextData}>
            {content}
        </CardsContext.Provider>
    );
};
