import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
const UserFirstTime = ({ children }) => {
    const { user } = useContext(AuthContext);
    if (user && user?.first_login)
        return <Navigate to={"/survey"} replace></Navigate>;
    return children;
};

export default UserFirstTime;
