import React, { useContext, useState, useRef } from "react";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { loadingOff, loadingOn } from "../utils/loading";
import ReCAPTCHA from "react-google-recaptcha";

const HelpPage = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    let captcha = useRef(null);

    async function sendQuestion(e) {
        e.preventDefault();
        if (
            e.target.email.value !== "" &&
            e.target.title.value !== "" &&
            e.target.question.value !== "" &&
            captcha.current !== null
        ) {
            loadingOn();
            const response = await fetch("/api/send-question/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    user: user?.user_id,
                    email: e.target.email.value,
                    title: e.target.title.value,
                    question: e.target.question.value,
                }),
            });
            if (response.status === 200) {
                setMessage("Great, we will check your question and answer");
                e.target.title.value = "";
                e.target.question.value = "";
                loadingOff(message);
            } else {
                setMessage("Something went wrong, sorry");
                loadingOff(message);
            }
        } else if (
            e.target.email.value === "" ||
            e.target.title.value === "" ||
            e.target.question.value === ""
        ) {
            setMessage("inputs are blank");
        } else if (captcha.current === null) {
            setMessage("Are you a robot?");
        }
    }
    function onChange(value) {
        captcha.current = value;
    }

    return (
        <>
            <h1 data-testid="h1-1">{t("Welcome in help section 👋")}</h1>
            <p>
                {t(
                    "You can contact us using the form below. We are happy to answer your question."
                )}
            </p>
            <form onSubmit={sendQuestion} id="form-question">
                <label htmlFor="email">{t("e-mail")}</label>
                <input
                    type="email"
                    placeholder="e-mail"
                    defaultValue={user?.email}
                    name="email"
                    maxLength={100}
                    id="email"
                />
                <label htmlFor="title">{t("title")}</label>
                <input
                    id="title"
                    type="text"
                    placeholder={t("title")}
                    name="title"
                    maxLength={100}
                />
                <label htmlFor="question">{t("question")}</label>
                <textarea
                    type="text"
                    id="question"
                    name="question"
                    maxLength={1000}
                />
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
                    onChange={onChange}
                />
                <p className="warning">{message}</p>
                <input id="btn" type="submit" value={t("Submit")} />
            </form>
        </>
    );
};

export default HelpPage;
