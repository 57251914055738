import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";

const Success = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    return (
        <div className="visible">
            <div className="modify">
                <h1>{t("Success")}</h1>
                <p>{t()}</p>
                <p>
                    {t("You have a premium account to ")}
                    {user?.premium_expired_date.slice(0, 10)}
                </p>
                <Link to="/">{t("close")}</Link>
            </div>
        </div>
    );
};

export default Success;
