import React, { useContext, useState } from "react";
import NewCard from "../components/NewCard";
import AllCards from "../components/AllCards";
import Lesson from "../components/Lesson";
import SectionManagement from "../components/SectionManagement";
import CategoryManagement from "../components/CategoryManagement";
import { Outlet, Route, Routes, Link, useNavigate } from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute";
import AuthContext, { AuthProvider } from "../context/AuthContext";
import CardsContext from "../context/CardsContext";
import { useTranslation } from "react-i18next";
import bg from "../photos/wave-haikei.svg";

import gears from "../photos/settings.png";
import CurrentCategoryContext from "../context/CurrentCategoryContext";
import LandingPage from "./LandingPage";
import Success from "../components/Success";
import AccountPage from "./AccountPage";

const CardsPage = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { currentCategory } = useContext(CurrentCategoryContext);
    const { cards, allSections, getCards, loadingPage } =
        useContext(CardsContext);
    const { t } = useTranslation();

    try {
        const sectionObjectsTemp = allSections
            ?.map((e) => {
                if (e.category === currentCategory?.id) {
                    return e;
                }
                return 0;
            })
            .filter((e) => e)
            .sort((a, b) => a.timer - b.timer);
    } catch (error) {
        window.location = `${window.location.origin}/pay`;
        return <></>;
    }
    const sectionObjectsTemp = allSections
        ?.map((e) => {
            if (e.category === currentCategory?.id) {
                return e;
            }
            return 0;
        })
        .filter((e) => e)
        .sort((a, b) => a.timer - b.timer);
    const known = sectionObjectsTemp.splice(1, 1);
    const sectionObjects = [...sectionObjectsTemp, ...known];
    const sections = sectionObjects
        .map((e) => {
            return e.id;
        })
        .filter((e) => e);
    const numbersOfCardsForLessons = sections.map((e) => 0);
    const timers = sectionObjects
        ?.map((e) => {
            if (sections.includes(e.id)) {
                return Number(e.timer);
            }
            return 0;
        })
        .filter((e) => {
            if (e !== undefined || e !== 0) return e + 1;
        });
    const labels = sectionObjects?.map((e) => {
        return e.label;
    });

    // console.log(cards);
    // Check if something Changed (maybe card is now available)
    // check how many cards is available for a lesson
    sections?.forEach((section, index) => {
        const now = new Date();
        cards?.forEach((card) => {
            const modified = new Date(card.modified_date.slice(0, 19));
            let delta = now - modified;
            // console.log(now, modified);
            // if (delta < 0) {
            //     delta = 0;
            // }
            if (section === card.section && delta >= timers[index]) {
                numbersOfCardsForLessons[index]++;
            }
        });
    });

    // preapare cards boxes' JSX for a component
    const boxes = numbersOfCardsForLessons.map((val, i) => {
        return (
            <div key={i} className="card-box">
                <h2 className="blue">{t(labels[i])}</h2>
                <span className="section-info">
                    <h3>
                        {t("Cards")}:{" "}
                        {!cards ? (
                            <span className="loading loading-text">?</span>
                        ) : (
                            cards?.filter((obj) => obj.section === sections[i])
                                .length
                        )}
                    </h3>
                    <h3>
                        {t("Available")}: {val}
                    </h3>
                </span>
                <div>
                    <Link
                        className={val ? "learn-btn " : "invisible"}
                        to={`lesson/${sections[i]}`}
                        onClick={() => {
                            // const body =
                            //     document.getElementsByTagName("body")[0];
                            // body.style.height = "100%";
                            // body.style.overflow = "hidden";
                            // console.log(body);
                        }}
                    >
                        {t("Learn")}
                    </Link>
                </div>
            </div>
        );
    });

    return (
        <main>
            {loadingPage && !user ? (
                <LandingPage />
            ) : (
                // <h1>{t("Login to use the app")}</h1>
                <AuthProvider>
                    {/* <div className="circle"></div> */}
                    <section id="panel">
                        <p id="below-h1">
                            {currentCategory?.name.toUpperCase()}
                        </p>
                        {/* <img src={user.image_social} alt="zdjecie" /> */}
                        <div id="buttons">
                            <Link className="below" to="list">
                                {t("All cards")}
                            </Link>
                            <Link className="below" to="add">
                                {t("Add card")}
                            </Link>
                        </div>
                    </section>
                    <section id="cards-big-box">
                        {boxes}{" "}
                        <Link to="section" className="settings-section">
                            <img
                                src={gears}
                                alt="Settings"
                                id="settings-section"
                            />
                        </Link>
                        <img src={bg} alt="" className="bg-for-parent1" />
                    </section>

                    <Routes>
                        <Route path="/" element={<Outlet />} />
                        <Route path="/success" element={<Success />} />
                        <Route
                            path="/account"
                            element={
                                <PrivateRoute user={user}>
                                    <AccountPage
                                        currentCategory={currentCategory}
                                        labels={labels}
                                        sections={sections}
                                        refresh={getCards}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/add"
                            element={
                                <PrivateRoute user={user}>
                                    <NewCard
                                        currentCategory={currentCategory}
                                        labels={labels}
                                        sections={sections}
                                        refresh={getCards}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/modify/:section/:id"
                            element={
                                <PrivateRoute user={user}>
                                    <NewCard
                                        currentCategory={currentCategory}
                                        labels={labels}
                                        sections={sections}
                                        refresh={getCards}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/list"
                            element={
                                <PrivateRoute user={user}>
                                    <AllCards
                                        refresh={getCards}
                                        sections={sections}
                                        timers={timers}
                                        cards={cards}
                                        labels={labels}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/lesson/:section"
                            element={
                                <PrivateRoute user={user}>
                                    <Lesson
                                        sections={sections}
                                        timers={timers}
                                        cards={cards}
                                        refresh={getCards}
                                    />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/section"
                            element={
                                <PrivateRoute user={user}>
                                    <SectionManagement
                                        sections={sections}
                                        timers={timers}
                                        labels={labels}
                                        refresh={getCards}
                                        sectionObjects={sectionObjects}
                                        currentCategory={currentCategory}
                                    />
                                </PrivateRoute>
                            }
                        />{" "}
                        <Route
                            path="/categories"
                            element={
                                <PrivateRoute user={user}>
                                    <CategoryManagement
                                        currentCategory={currentCategory}
                                    />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </AuthProvider>
            )}
            {/* <img src={bg} alt="" className="bg-for-parent1" /> */}
        </main>
    );
};

export default CardsPage;
