import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../components/CheckoutForm";
const stripePromise = loadStripe(
    "pk_live_51PRSxiKmpKVrHuGGOeoyclHYzjqv7ljUMVC7lg08CaPOswkPtXfOg6AIjLbKB2r73OsZzhap1NaITjiqlqkQCdvJ00ZmyL1Nym"
    // "pk_test_51PRSxiKmpKVrHuGGQSC6M5JDw4s3pBo2KxrZD7iPZ69AoSSO674VVJ48d6fExm0Cb15F0pyYESxok75eviBcfhMa00JVhVYCfT"
);
const Pay = () => {
    const [clientSecret, setClientSecret] = useState("");
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        if (user?.email) {
            fetch("/api/payment/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({}),
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));
        } else {
            navigate("/login");
        }
    }, []);
    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <div style={{ zIndex: 2 }}>
            {" "}
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    );
};

export default Pay;
