import React, { useState, createContext, useContext } from "react";
import CardsContext from "./CardsContext";

const CurrentCategoryContext = createContext();

export default CurrentCategoryContext;

export const CurrentCategoryProvider = ({ children }) => {
    const { allCategories } = useContext(CardsContext);

    const [currentCategory, setCurrentCategory] = useState(
        allCategories?.at(localStorage.getItem("category_index")) ||
            allCategories?.at(0)
    );
    if (!localStorage.getItem("category_index")) {
        localStorage.setItem(
            "category_index",
            allCategories?.indexOf(currentCategory) === -1
                ? 0
                : allCategories?.indexOf(currentCategory)
        );
    }
    if (
        allCategories?.at(localStorage.getItem("category_index"))?.name !==
        currentCategory?.name
    ) {
        setCurrentCategory(
            allCategories?.at(localStorage.getItem("category_index"))
        );
    }
    const contextData = {
        currentCategory: currentCategory,
        setCurrentCategory: setCurrentCategory,
    };
    return (
        <CurrentCategoryContext.Provider value={contextData}>
            {children}
        </CurrentCategoryContext.Provider>
    );
};
