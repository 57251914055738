import React, { useContext, useEffect } from "react";
import CardsContext from "../context/CardsContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import note from "../photos/yellow-card.png";
import { Link } from "react-router-dom";
import Confirmation from "../components/Confirmation";
import setErrorPage from "../utils/errorPage";

const AccountPage = () => {
    const { user, logoutUser, authTokens } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { cards, allSections } = useContext(CardsContext);

    useEffect(() => {
        if (!user?.email) {
            navigate("/login");
        } else if (cards?.length !== 0) {
            cards?.forEach((element) => {
                if (element.section === allSections.at(-1).id) {
                    knownCards += 1;
                }
            });
        }
    }, []);
    let knownCards = 0;

    async function deleteUser() {
        const response = await fetch("/api/delete_user/", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
        });
        const data = await response.json();
        if (response.status === 200) {
            // console.log(data);
            navigate("/");
            logoutUser();
        } else {
            setErrorPage(data.data);
        }
    }
    return (
        <>
            <h1>{t("Your profile")}</h1>
            <Confirmation
                id="delete_user"
                func={() => deleteUser()}
                message=" It will delete all your cards, sections and categories."
            />
            <div className="two_colums_photo_text">
                <img src={note} alt="yellow card" className="right_column" />
                <div className="left_column">
                    <h2>
                        {t("Username: ")}
                        {user?.username}
                    </h2>
                    <h2>
                        {t("Email: ")} {user?.email}
                    </h2>
                    <h2>
                        {t("All cards: ")} {cards?.length}
                    </h2>
                    <h2>
                        {t("Known cards: ")} {knownCards}
                    </h2>
                    <h2>
                        {t("Premium expired: ")}{" "}
                        {`${user?.premium_expired_date}`.slice(0, 10)}
                    </h2>
                    <div>
                        <Link to={"/pay"}>{t("extend subscription")}</Link>
                        <Link
                            to={"/"}
                            onClick={() => {
                                navigate("/");
                                logoutUser();
                            }}
                        >
                            {t("Logout")}
                        </Link>
                    </div>
                    <div className="divider"></div>
                    <button
                        className="white-link bad"
                        onClick={() => {
                            document.querySelector(
                                `.confirmationdelete_user`
                            ).style.display = "grid";
                        }}
                    >
                        {t("Delete user")}
                    </button>
                    {/* BUG BUG NOTE BUG BUG */}
                </div>
            </div>
        </>
    );
};

export default AccountPage;
