import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadingOff, loadingOn } from "../utils/loading";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import bush from "../photos/bush.svg";
import tent from "../photos/Component 6.svg";
import sun from "../photos/Component 10.svg";
import moon from "../photos/Component 11.svg";

const LoginPage = () => {
    const { t } = useTranslation();
    const { loginUser, message, responseFacebook, googleAuth } =
        useContext(AuthContext);

    return (
        <>
            {/* 
            <img src={star} alt="" className="star-login" /> */}
            <div className="photo-and-form">
                <div className="form-photo">
                    <h1>{t("Login and learn with us everywhere!")}</h1>
                    <img
                        src={
                            localStorage.getItem("theme") === "dark"
                                ? moon
                                : sun
                        }
                        alt=""
                        className="sun"
                    />
                    <div className="tent-and-bush">
                        <img src={tent} alt="" className="tent" />
                        <img src={bush} alt="" className="bush" />
                    </div>
                </div>
                <form
                    onSubmit={(e) => {
                        loadingOn(e);
                        loginUser(e, loadingOff);
                    }}
                    className="user-form"
                >
                    <h1>Login</h1>
                    <label htmlFor="email">{t("e-mail")}</label>
                    <input
                        type="email"
                        name="email"
                        placeholder={t("tom@gmail.com")}
                        maxLength={200}
                    />
                    <label htmlFor="password">{t("password")}</label>
                    <input
                        type="password"
                        name="password"
                        maxLength={100}
                        placeholder="*******"
                    />
                    <label>{t("sign in with")}</label>
                    <div className="display_social_flex">
                        <GoogleOAuthProvider
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        >
                            <GoogleLogin
                                type="icon"
                                width="20px"
                                onSuccess={googleAuth}
                                onError={() => {
                                    console.log("Login Failed");
                                }}
                            />
                        </GoogleOAuthProvider>
                        <LoginSocialFacebook
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            fieldsProfile="id,name,picture,email"
                            onResolve={({ provider, data }) => {
                                responseFacebook(data);
                                // console.log(data);
                            }}
                            onReject={(error) => {
                                console.log(error);
                            }}
                        >
                            <FacebookLoginButton
                                style={{
                                    fontSize: "15px",
                                    borderRadius: "10px",
                                    paddingLeft: "13px",
                                }}
                                iconSize="20px"
                                text=""
                                className="fblogin"
                            />
                        </LoginSocialFacebook>
                    </div>
                    {/* <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        textButton={t("Login with facebook")}
                        cssClass="fb-login"
                        icon={
                            <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="512.000000pt"
                                height="512.000000pt"
                                viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <g
                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#ddd"
                                    stroke="none"
                                >
                                    <path
                                        d="M490 5104 c-232 -49 -426 -246 -475 -479 -22 -105 -22 -4025 0 -4130
                        38 -180 163 -344 326 -424 149 -73 101 -71 1263 -71 l1038 0 39 22 c25 14 46
                        37 59 63 20 41 20 57 18 998 -3 954 -3 956 -24 984 -12 15 -36 37 -55 48 -31
                        19 -55 20 -321 23 l-288 3 0 215 0 214 294 0 c424 0 396 -33 396 465 0 395 6
                        441 67 565 50 103 160 213 263 263 120 59 176 67 503 67 l287 0 0 -215 0 -215
                        -275 0 c-300 0 -317 -3 -367 -56 -15 -16 -32 -42 -37 -57 -7 -18 -11 -151 -11
                        -365 0 -376 1 -384 69 -429 33 -23 39 -23 327 -23 l294 0 0 -214 0 -215 -287
                        -3 c-267 -3 -291 -4 -322 -23 -19 -11 -43 -33 -55 -48 -21 -28 -21 -30 -24
                        -989 l-2 -960 21 -38 c12 -21 38 -47 59 -59 37 -21 45 -21 661 -21 702 0 705
                        1 848 71 163 80 288 244 326 424 22 105 22 4025 0 4130 -49 236 -244 431 -480
                        480 -102 21 -4035 21 -4135 -1z m4060 -294 c139 -26 234 -121 260 -259 14 -74
                        14 -3908 0 -3982 -26 -138 -121 -233 -260 -259 -31 -6 -262 -10 -556 -10
                        l-504 0 0 764 0 765 293 3 c335 3 343 5 385 88 23 45 23 46 20 447 l-3 401
                        -31 39 c-17 21 -46 43 -65 50 -23 8 -125 12 -316 12 l-283 1 0 165 0 165 291
                        0 c326 0 340 2 381 69 23 36 23 37 23 448 0 402 0 412 -21 440 -11 15 -36 38
                        -55 51 l-34 23 -435 -3 -435 -4 -88 -27 c-321 -100 -536 -318 -630 -642 -18
                        -62 -21 -105 -25 -377 l-4 -308 -284 0 c-319 0 -332 -2 -380 -66 l-29 -37 -3
                        -401 c-4 -567 -32 -529 406 -534 l292 -3 0 -765 0 -764 -919 0 c-581 0 -938 4
                        -972 10 -138 26 -233 121 -259 260 -14 73 -14 3908 0 3981 26 134 122 233 252
                        258 63 13 3923 13 3988 1z"
                                    />
                                </g>
                            </svg>
                        }
                    /> */}

                    <p className="warning">{t(message)}</p>

                    <input id="btn" type="submit" value={t("Submit")} />
                    <p>
                        {t("Forgot yout password?")}{" "}
                        <Link className="blue-link" to="/reset_password/">
                            {t("reset password")}
                        </Link>
                    </p>
                </form>
                {/* <img src={bg} alt="" className="bg-for-parent2" /> */}
            </div>
        </>
    );
};

export default LoginPage;
