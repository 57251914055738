import React from "react";
import { Link } from "react-router-dom";
const TermsOfUse = () => {
    return (
        <div className="content-document">
            <h1>Regulamin Ycards</h1>
            <p>
                Witamy Cię w Ycards, aplikacji do nauki. Nasza platforma będzie
                śledziła Twoje postępy. W przyszłości z pewnością pojawi się
                więcej opcji, a zatem dołącz do nas już teraz!
            </p>
            <h2>Postanowienia ogólne</h2>
            <p>
                Niniejszy Regulamin (“Regulamin”) skierowany jest do
                Użytkowników (“Użytkownik”, “Ty”, “Twój”, “Ci”…) “Ycards”
                (“Ycards”,”Platforma”, “my”, “nasz”) – (i) strony internetowej:
                www.ycards.pl (“Strona Internetowa”) oraz aplikacji mobilnej
                (“Aplikacja”). Określa on zasady korzystania z świadczonych
                przez nas usług (Usługi) oraz zasady i tryb zawierania Umów
                Sprzedaży z Klientem na odległość (“Umowy”) za pośrednictwem
                Ycards. Z chwilą akceptacji Regulaminu oświadczasz, że
                zapoznałeś się z jego treścią, akceptujesz wszystkie jego
                warunki i zobowiązujesz się do ich przestrzegania. W
                Regulaminie, jak i w Polityce Prywatności, mogą się pojawić
                zmiany, o których będziemy Cię na bieżąco informować. Jeśli
                zdecydujesz się korzystać z naszych Usług, oznacza to, że
                akceptujesz nasz Regulamin oraz wszystkie przyszłe zmiany, które
                mogą się w nim pojawić. Jeśli nie zgadzasz się z treścią
                Regulaminu, prosimy o niekorzystanie z naszych Usług. W
                przypadku złamania Regulaminu, możemy zablokować Twój dostęp do
                naszych Usług.
            </p>
            <h2>1. REJESTRACJA</h2>
            <p>
                Do korzystania z naszych Usług konieczna jest rejestracja w celu
                utworzenia własnego konta (“Konto”), której możesz dokonać
                poprzez:
                <ul style={{ textAlign: "left" }}>
                    <li>
                        Połączenie konta z mediami społecznościowymi (Facebook,
                        Gmail);
                    </li>
                    <li>
                        podanie swojego adresu e-mail oraz stworzenie swojej
                        nazwy użytkownika i hasła;
                    </li>
                </ul>
                Dane logowania możesz zawsze zmienić. Pamiętaj jednak, aby nie
                udostępniać swojego Konta ani nie powierzać nikomu swojego
                hasła. Twoje Konto należy wyłącznie do Ciebie, nie możesz go
                współdzielić z innymi osobami ani go nikomu przekazywać.
                Ponosisz odpowiedzialność za każdą aktywność na Twoim Koncie,
                dlatego w razie podejrzenia o nieautoryzowanym zalogowaniu na
                swoim Koncie, niezwłocznie poinformuj nas o tym, wysyłając
                wiadomość na adres e-mail: mailbox@ycards.pl.
            </p>
            <h2>2. POLITYKA PRYWATNOŚCI</h2>
            <p>
                Jako administrator danych osobowych (“Administrator”)
                przykładamy dużą wagę do ochrony prywatności i poufności danych
                osobowych przekazywanych przez Użytkowników bezpośrednio
                Administratorowi. Z należytą starannością dobieramy i stosujemy
                odpowiednie środki techniczne i organizacyjne zapewniające
                ochronę przetwarzanych danych osobowych. Wszelkie dane osobowe,
                które przekazujesz nam, zakładając Konto, będą podlegać naszej
                <span> </span>
                <Link
                    to={"/privacy-policy"}
                    style={{ textDecoration: "underline" }}
                    className="white-link"
                >
                    Polityce Prywatności
                </Link>
                .
            </p>
            <h2>3. NASZE TREŚCI</h2>
            <p>
                Treści dostępne w Ycards (“Treści”) stanowią własność naszą lub
                naszych partnerów (nie wchodzą do nich Treści publikowane przez
                Użytkowników). Użytkownicy nie mają prawa przywłaszczać sobie
                naszych Treści, handlować nimi lub w jakikolwiek inny sposób
                bezprawnie ich użyć. Do naszych Treści należą między innymi:
                <ul style={{ textAlign: "left" }}>
                    <li>nazwa "Ycards";</li>
                    <li>dane i kody, oprogramowanie, strona internetowa;</li>
                    <li>design, wzory, grafiki, ilustracje, schematy, logo;</li>
                    <li>
                        materiały audiowizualne, materiały tekstowe,
                        przygotowane zestawy do nauki, nazwy setów i ich
                        organizacja.
                    </li>
                </ul>
            </p>
            <h2>
                4. TREŚCI OSÓB TRZECICH I LINKI DO STRON INTERNETOWYCH OSÓB
                TRZECICH
            </h2>
            <p>
                W Ycards mogą się pojawić Treści osób trzecich pod licencją lub
                linki do ich stron internetowych. Nie mamy wpływu na treści ani
                na linki publikowane na stronach internetowych osób trzecich i
                nie bierzemy za nie odpowiedzialności. Treści osób trzecich,
                które będą Ci dostarczane mają za zadanie jedynie usprawnić i
                uprzyjemnić Twoją naukę. Rozumiesz, że Treści osób trzecich nie
                znajdują się pod naszą kontrolą i nie jesteśmy odpowiedzialni za
                szkody przez nie spowodowane. Regulamin nie dotyczy serwisów i
                firm osób trzecich.
            </p>
            <h2>5. TREŚCI UŻYTKOWNIKA</h2>
            <p>
                Do treści publikowanych przez Użytkownika (“Treści Użytkownika”)
                należą wszystkie treści oraz materiały publikowane przez
                Użytkownika w naszej Aplikacji lub na naszej Stronie
                Internetowej. Publikując Treści Użytkownika oświadczasz, że
                jesteś ich właścicielem lub że masz zezwolenie osób trzecich na
                ich publikowanie. Rozumiesz również, że publikując Treści
                Użytkownika, oddajesz nam bezpłatną i nieodwołalną licencję na
                wykorzystanie Twoich Treści w całości lub w części.
                <br />
                Użytkownicy ponoszą pełną odpowiedzialność za treści publikowane
                w domenach publicznych (“Treści Publiczne”) oraz zobowiązują się
                do udostępniania treści zgodnych z niniejszym Regulaminem.
                <br />
                Administrator nie gwarantuje dokładności, poprawności czy też
                prawidłowości Treści Użytkownika oraz nie bierze za nie
                odpowiedzialności.
                <br />
                Monitorujemy wszystkie Treści Użytkownika oraz zastrzegamy sobie
                prawo do usunięcia Twoich Treści Publicznych, jeśli uznamy je za
                niezgodne z Regulaminem.
                <br />
                Treści Użytkownika niezgodne z niniejszym Regulaminem to:
                <ul style={{ textAlign: "left" }}>
                    <li>
                        Treści, których Użytkownik nie jest właścicielem i nie
                        ma praw bądź zgody na ich publikowanie;
                    </li>
                    <li>
                        Treści obraźliwe, nakłaniające do nienawiści bądź
                        przemocy, wulgarne, nieprzyzwoite, naruszające czyjś
                        wizerunek lub w jakikolwiek sposób dyskryminujące
                        (rasizm, seksizm, dyskryminacja ze względu na wyznawaną
                        religię, przekonania, opinie polityczne, itp.);
                    </li>
                    <li>Treści zawierające dane osobiste osób trzecich;</li>
                    <li>Treści nakłaniające do zakupu oraz spam;</li>
                    <li>
                        Treści nielegalne lub nakłaniające do nielegalnych
                        aktów;
                    </li>
                    <li>
                        Treści, które celowo wprowadzałyby innych Użytkowników w
                        błąd;
                    </li>
                    <li>
                        inne Treści, które Administrator uzna za raniące lub
                        krzywdzące zarówno Użytkowników jak i nas.
                    </li>
                </ul>
            </p>
            <h2>6. ZACHOWANIE UŻYTKOWNIKA</h2>
            <p>
                Zależy nam na stworzeniu bezpiecznej przestrzeni do nauki,
                dlatego Użytkownik zobowiązany jest do przestrzegania Regulaminu
                i rozumie, że nie może:
                <ul style={{ textAlign: "left" }}>
                    <li>
                        Zastraszać, krytykować, dyskryminować ani w jakikolwiek
                        inny sposób ranić innych Użytkowników i tym samym
                        naruszać ich spokoju;
                    </li>
                    <li>
                        rościć sobie praw do własności intelektualnej naszej,
                        naszych partnerów lub innych Użytkowników;
                    </li>
                    <li>umyślnie wprowadzać innych Użytkowników w błąd;</li>
                    <li>podawać fałszywych informacji;</li>
                    <li>
                        zbierać dane innych Użytkowników, nazw, haseł, loginów,
                        adresów e-mail;
                    </li>
                    <li>
                        korzystać z naszych Usług w celu nakłaniania do zakupu
                        produktu własnego lub osób trzecich, spamowania bądź
                        przekierowywania do innych stron internetowych;
                    </li>
                    <li>
                        umyślnego uszkadzania bądź przeciążania Aplikacji lub
                        Strony Internetowej;
                    </li>
                    <li>sprzedaży Kont, powielania Kont.</li>
                </ul>
            </p>
            <h2>7. LICENCJA UŻYTKOWNIKA</h2>
            <p>
                Użytkownik dostaje licencję (“Licencja”) na korzystanie z
                Serwisu na Stronie Internetowej oraz w Aplikacji na użytek
                własny, niekomercyjny pod warunkiem przestrzegania postanowień
                Regulaminu. Kopiowanie, rozpowszechnianie lub sprzedawanie
                Licencji osobom trzecim jest zabronione. Warunkiem otrzymania
                Licencji jest zatwierdzenie oraz przestrzeganie Regulaminu. W
                przypadku złamania Regulaminu, Administrator ma prawo do
                odebrania Licencji.
            </p>
            <h2>8. OPŁATY</h2>
            <p>
                Korzystanie z Aplikacji czy też Strony Internetowej jest płatne.
                W ramach naszej Platformy możesz dodawać treści, z których
                będziesz następnie korzystał (fiszki/karty). W ten sposób
                będziesz mógł uczyć się i powtarzać. Jeśli nasz produkt spodoba
                Ci się i zdecydujesz się na zakup konta premium, będziesz mógł
                wykupić je u nas na zasadzie subskrypcji (“Subskrypcja”).
                Wszystkie opcje Subskrypcji jak i ich ceny znajdziesz pod tym
                linkiem. Opłaty za subskrypcję mogą się zmieniać w związku z
                różnymi planami subskrypcji czy też promocjami, a zatem
                zastrzegamy sobie prawo do bieżącej aktualizacji naszej oferty,
                którą znajdziesz pod wcześniej wspomnianym linkiem.
                <br></br>
                Ceny Subskrypcji mogą ulec zmianom, o których będziemy Cię na
                bieżąco informowali.
            </p>
            <h2>9. GWARANCJA</h2>
            <p>
                Możesz zrezygnować z Subskrypcji, jeśli w ciągu 14 dni od jej
                wykupienia stwierdzisz, że nasze Usługi nie spełniają Twoich
                oczekiwań. Zwrócimy Ci wtedy 100% Twojej wpłaty, bez prośby do
                podania przyczyny, wystarczy, że napiszesz do nas na
                mailbox@ycards.pl. Aby móc uzyskać zwrot pieniędzy, ważne jest,
                aby się zgłosić do nas w ciągu 14 dni od rozpoczęcia okresu
                Subskrypcji, w innym przypadku nie otrzymasz zwrotu. W menu
                aplikacji po najechaniu na ikonę osoby na stronie internetowej
                oraz po naciśnięciu przycisku “Twoje konto” (’Your profile’) w
                nagłówku znajdziesz dane dotyczące Twojej Subskrypcji, w tym
                datę jej zakończenia.
            </p>
            <h2>10. USUWANIE KONTA</h2>
            <p>
                W każdej chwili możesz usunąć swoje Konto, naciskając przycisk
                ‘Usuń Konto’ (’delete user’). Opcja jest nieodwracalna. Usuwając
                swoje konto – Użytkownik traci swoją Licencję, wszystkie dane z
                jego Konta zostają wykasowane, a aktywne Subskrypcje zostają
                anulowane bez zwrotu pieniędzy za pozostały czas Subskrypcji.
                Administrator nie bierze odpowiedzialności za zabezpieczenie
                danych opublikowanych przez Użytkowników na naszej Stronie
                Internetowej i w Aplikacji. W przypadku złamania Regulaminu,
                Administrator ma prawo do zawieszenia lub całkowitego usunięcia
                Konta Użytkownika. Użytkownik zostanie poinformowany zarówno o
                samej decyzji zawieszenia lub usunięcia Konta, jak i o powodzie,
                dla którego taka decyzja została podjęta. Usunięcie Konta jest
                nieodwracalne, wraz z usunięciem Konta, usunięty zostaje profil
                Użytkownika, jego dane, a aktywne Subskrypcje zostają anulowane
                bez zwrotu pieniędzy za pozostały czas Subskrypcji.
                Administrator nie bierze też odpowiedzialności za zabezpieczenie
                danych opublikowanych przez Użytkowników.
            </p>
            <h2>11. Zwroty, reklamacje, anulowanie zamówienia</h2>
            <p>
                <ul style={{ textAlign: "left" }}>
                    <li>
                        W przypadku otrzymania wadliwego produktu, Użytkownik ma
                        prawo do złożenia reklamacji i otrzymania zwrotu
                        pieniędzy.
                    </li>
                    <li>
                        Administrator jest zwolniony od odpowiedzialności z
                        tytułu rękojmi za wadę, jeżeli Klient wiedział o wadzie
                        produktu w chwili zawarcia umowy sprzedaży. Opis wady
                        produktu będzie każdorazowo wskazany na Stronie.
                    </li>
                    <li>
                        Administrator niezwłocznie, jednak najpóźniej w ciągu 14
                        dni od daty otrzymania przesyłki z reklamowanym
                        produktem, ustosunkuje się do złożonej reklamacji i
                        poinformuje Klienta o dalszym postępowaniu.
                    </li>
                </ul>
            </p>
            <h2>12. OGRANICZENIE ODPOWIEDZIALNOŚCI</h2>
            <p>
                Wiemy, że proces nauki, gdy uczymy się na własną rękę, nie
                zawsze wydaje się prosty. Dlatego stworzyliśmy dla Ciebie Stronę
                internetową i Aplikację Ycards, aby pomóc Ci w nauce i pokazać,
                że wcale nie musi być ona żmudna ani trudna. Staramy się ułatwić
                Ci naukę. Niemniej, nasze metody mogą działać dla każdego
                Użytkownika inaczej.
            </p>
            <h2>13. Kontakt</h2>
            <p>
                Zawsze możesz się z nami skontaktować za pomocą adresu e-mail:
                mailbox@ycards.pl<br></br>
                Życzymy owocnej nauki
                <br />
                Zespół Ycards
            </p>
        </div>
    );
};

export default TermsOfUse;
