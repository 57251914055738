import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");

    function loadingOn() {
        const inputs = document.querySelectorAll(`input`);
        console.log(inputs);
        inputs.forEach((element) => {
            element.classList.add("loading");
        });
        return inputs;
    }
    function loadingOff(arr) {
        arr.forEach((element) => {
            element.classList.remove("loading");
        });
    }

    async function sendEmail(e) {
        e.preventDefault();
        if (e.target.email.value !== "") {
            const inputs = loadingOn();
            const response = await fetch("/api/reset_password/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: e.target.email.value }),
            });
            const data = await response.json();
            loadingOff(inputs);
            setMessage(data.message);
        }
    }
    return (
        <>
            <form onSubmit={(e) => sendEmail(e)}>
                <h3>{t("Reset password")}</h3>
                <p>{t("We will send you a link to reset your password.")}</p>
                <input
                    id="reset-password"
                    type="email"
                    name="email"
                    placeholder="e-mail"
                />
                <p>{message}</p>
                <input type="submit" value={t("Submit")} />
            </form>
        </>
    );
};

export default ResetPassword;
