import React from "react";
import { useTranslation } from "react-i18next";

const Confirmation = (props) => {
    const { t } = useTranslation();
    function hide() {
        const element = document.querySelector(`.confirmation${props.id}`);
        element.style.display = "none";
    }
    // console.log(`${window.location}`.slice(-10) === "categories");
    return (
        <div id={`confirmation`} className={`confirmation${props.id}`}>
            <div className="modify">
                <h2>
                    {t("Are you sure?")}
                    {t(props?.message)}
                    <br></br>

                    {`${window.location}`.slice(-10) === "categories"
                        ? t("it will delete your cards")
                        : t("")}
                </h2>
                <button
                    onClick={() => {
                        props.func();
                        hide();
                    }}
                >
                    {t("Yes")}
                </button>
                <button onClick={hide}>{t("No")}</button>
            </div>
        </div>
    );
};

export default Confirmation;
