import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { loadingOn, loadingOff } from "../utils/loading";
import { jwtDecode } from "jwt-decode";

const Survey = () => {
    const [message, setMessage] = useState("");
    const { user, authTokens, setAuthTokens, setUser } =
        useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!user?.first_login) {
            navigate("/");
        }
    }, []);

    async function submit(e) {
        e.preventDefault();
        if (e.target.category.value === "") {
            console.log("blank");
        } else {
            loadingOn();
            const response = await fetch("/api/survey1/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(authTokens?.access),
                },
                body: JSON.stringify({
                    category_name: e.target.category.value,
                }),
            });
            const data = await response.json();
            if (response.status === 200) {
                setAuthTokens(data);
                setUser(jwtDecode(data.access));
                localStorage.setItem("authTokens", JSON.stringify(data));
                navigate("/success");
            } else {
                setMessage(data);
            }
            loadingOff();
        }
    }
    console.log(user);
    return (
        <article>
            <h1>{t("We have only one question for you.")}</h1>
            <h2>{t("What would you learn with us?")}</h2>
            <form onSubmit={submit}>
                <input
                    type="text"
                    name="category"
                    placeholder={t("spanish?")}
                />
                <p className="warning">{t(message)}</p>
                <input id="btn" type="submit" value={t("Confirm")} />
            </form>
        </article>
    );
};

export default Survey;
