import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import setErrorPage from "../utils/errorPage";
import arrow from "../photos/Arrow.svg";

const NewCard = (props) => {
    const { authTokens } = useContext(AuthContext);

    const { t } = useTranslation();
    let word = useRef("");
    let sec = useRef(1);
    let id = useRef(0);
    const [meanings, setMeanings] = useState("");

    const [oldWord, setOldWord] = useState();
    const [oldMeanings, setOldMeanings] = useState();
    const [oldSection, setOldSection] = useState();

    const labels = props.labels;
    const sections = props.sections;
    const [text, setText] = useState("");
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getOneCard();
    }, []);

    function loadingOn() {
        const input1 = document.querySelector(".add");
        const input2 = document.querySelector(".cancel");
        const select = document.querySelector("#section");
        input1.classList.add("loading");
        input2.classList.add("loading");
        select.classList.add("loading");
        return [input1, input2, select];
    }
    function loadingOff(arr) {
        arr.forEach((element) => {
            element.classList.remove("loading");
        });
    }

    async function getOneCard() {
        if (params.id) {
            const elements = loadingOn();
            const inputs = document.querySelectorAll("input");
            inputs.forEach((input) => {
                input.value = "?";
                input.classList.add("loading");
            });

            const response = await fetch(`/api/${params.id}/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(authTokens?.access),
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                // console.log(data);
                word.current.value = data.word;
                sec.current.value = data.section;
                id.current = data.id;
                setMeanings(data.meanings);
                setOldWord(data.word);
                setOldMeanings(data.meanings);
                setOldSection(data.section);
                inputs.forEach((input) => {
                    input.classList.remove("loading");
                });
            } else {
                setErrorPage();
            }
            loadingOff(elements);
        }
    }
    function addMeanigInputs() {
        if (meanings.length) {
            const arrOfMeanings = meanings.split("|");
            deleteAllMeaningInput();
            arrOfMeanings.forEach((value, index) => {
                prepareInput(index);
                document.querySelectorAll(".meaning")[index].value = value;
            });
        }
    }

    function changeWord(e) {
        word.current.value = e.target.value;
    }

    // prepare meaning for database
    function prepareMeaning() {
        const inputs = document.querySelectorAll(".meaning");
        let allMeanings = "";
        inputs.forEach((input) => {
            if (input.value.includes("|")) {
                setText(t("you can't use | sign sorryj:("));
                return null;
            } else if (input.value.trim() !== "") {
                allMeanings += input.value.trim() + "|";
            }
        });
        return allMeanings;
    }

    // send a PUT or POST request to backend and set the message determined by status code
    async function addNewCard(e) {
        e.preventDefault();
        const allMeanings = prepareMeaning();
        const date = new Date();
        const elements = loadingOn();
        if (word.current.value && allMeanings.length > 1) {
            const data = {
                id: id.current,
                category: props.currentCategory.id,
                modified_date: `${date.getFullYear()}-${String(
                    date.getMonth() + 1
                ).padStart(2, 0)}-${String(date.getDate()).padStart(
                    2,
                    0
                )} ${String(date.getHours()).padStart(2, 0)}:${String(
                    date.getMinutes()
                ).padStart(2, 0)}:${String(date.getSeconds()).padStart(2, 0)}`,
                word: word.current.value,
                meanings: allMeanings.slice(0, -1),
                section: sec.current.value,
            };
            if (params.id) {
                if (
                    String(data.word) !== String(oldWord) ||
                    String(data.meanings) !== String(oldMeanings) ||
                    String(data.section) !== String(oldSection)
                ) {
                    const response = await fetch(`/api/${params.id}/`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                "Bearer " + String(authTokens?.access),
                        },
                        body: JSON.stringify(data),
                    });
                    if (response.status === 200) {
                        props.refresh();
                        setMeanings(data.meanings);
                        setOldSection(data.section);
                        setOldMeanings(data.meanings);
                        setOldWord(data.word);
                        setText(t("Card was modified"));
                    } else {
                        setErrorPage();
                    }
                }
            } else {
                const response = await fetch("/api/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + String(authTokens?.access),
                    },
                    body: JSON.stringify(data),
                });
                // console.log(data);
                const card = await response.json();
                if (response.status === 200) {
                    afterCheckingRespone(card, "New card was added, ");
                } else if (response.status === 201) {
                    afterCheckingRespone(
                        card,
                        "Card was added|the word is used as meaning, "
                    );
                } else if (response.status === 202) {
                    afterCheckingRespone(
                        card,
                        "Card was added|the meaning is used as word, "
                    );
                } else if (response.status === 203) {
                    afterCheckingRespone(
                        card,
                        "Card was added|other card has the same meaning, "
                    );
                } else if (response.status === 300) {
                    afterCheckingRespone(card, "Card already exists, ", "bad");
                } else if (response.status === 301) {
                    setText(card.data);
                } else {
                    setErrorPage();
                }
            }
        }
        loadingOff(elements);
    }

    function afterCheckingRespone(card, mess, classForMessage = "") {
        deleteAllMeaningInput();
        prepareInput(0);
        const [mes1, ...mes2] = t(mess).split("|");
        word.current.value = "";
        setMeanings("");
        clearMeaningInputs();
        document.getElementById("word").focus();
        props.refresh();
        setText(
            <p className={classForMessage}>
                {mes1}
                <br />
                {mes2}

                <Link
                    className="blue-link"
                    to={`/modify/${card.section}/${card.id}`}
                    onClick={() => {
                        word.current.value = card.word;
                        setMeanings(card.meanings);
                        setOldWord(card.word);
                        setOldMeanings(card.meanings);
                        setOldSection(card.section);
                        sec.current.value = card.section;
                        id.current = card.id;
                        setText("");
                    }}
                >
                    {t("edit it")}
                </Link>
            </p>
        );
    }

    function pressedKey(e) {
        if (e.key === "ArrowDown" || e.key === "Enter") {
            e.preventDefault();
            if (e.target.id === "word") {
                document.querySelector(".meaning").focus();
            }
            if (e.target.className === "meaning") {
                addNewCard(e);
            }
        }
        if (e.key === "ArrowUp" && e.target.className === "meaning") {
            document.getElementById("word").focus();
        }
        if (e.key === "Escape") {
            navigate("/");
        }
    }

    // crate new input for the next meaning
    function addNewMeaningInput(e) {
        e.preventDefault();
        const allInputMeanings = document.querySelectorAll(".meaning");
        if (allInputMeanings.length < 7) {
            prepareInput(allInputMeanings.length);
            if (document.getElementById(`${allInputMeanings.length}`)) {
                document
                    .getElementById(`${allInputMeanings.length}`)
                    .childNodes[0].focus();
            }
            // document.querySelector(`#${allInputMeanings.length}`).focus();
        } else {
            setText(t("It is possible to add only 7 meanings"));
        }
    }
    function deleteMeaning(e) {
        e.preventDefault();
        // console.log(e.target.id);
        const allMeanings = document.querySelectorAll(".meaning");
        if (allMeanings.length > 1) {
            const meaningBox = document.getElementById(e.target.id);
            meaningBox.remove();
        } else {
            setText(t("Word without meaning? Interesting..."));
        }
    }
    function deleteAllMeaningInput() {
        const inputs = document.querySelectorAll(".meaning-box");
        inputs.forEach((input) => {
            input.remove();
        });
    }
    function clearMeaningInputs() {
        document.querySelectorAll(".meaning").forEach((element) => {
            element.value = "";
        });
    }
    const prepareInput = (id) => {
        const box = document.querySelector("#meaning-container");
        const parent = document.createElement("div");
        const input = document.createElement("input");
        const plusBtn = document.createElement("button");
        const minusBtn = document.createElement("button");

        input.setAttribute("type", "text");
        input.setAttribute("id", id);
        input.setAttribute("class", "meaning");
        input.setAttribute("maxLength", "200");
        input.setAttribute("placeholder", t("meaning"));
        input.addEventListener("keydown", (e) => pressedKey(e));

        plusBtn.setAttribute("class", "plus");
        plusBtn.setAttribute("id", id);
        plusBtn.innerHTML = "+";
        minusBtn.setAttribute("class", "minus");
        minusBtn.setAttribute("id", id);
        minusBtn.innerHTML = "-";

        plusBtn.addEventListener("click", addNewMeaningInput);
        minusBtn.addEventListener("click", deleteMeaning);

        parent.setAttribute("class", "meaning-box");
        parent.setAttribute("id", id);

        parent.appendChild(input);
        parent.appendChild(plusBtn);
        parent.appendChild(minusBtn);
        input.focus();
        box.appendChild(parent);
    };

    const options = sections.map((v, i) => (
        <option key={i} value={v} label={t(labels[i])}></option>
    ));

    function handleClick(e) {
        const value = e.target.value;
        const position = value.length;
        e.target.setSelectionRange(position - 1, position - 1);
    }

    window.addEventListener("keydown", escPressed);
    function escPressed(e) {
        if (e.code === "Escape") {
            window.removeEventListener("keydown", escPressed);
            navigate("/");
        }
    }
    clearMeaningInputs();
    addMeanigInputs();

    return (
        <div className="visible">
            <form className="modify">
                <Link
                    className="cancel back"
                    onClick={() => {
                        word.current.value = "";
                        setText("");
                        setMeanings("");
                        navigate(-1);
                        deleteAllMeaningInput();
                        params.id = "";
                        id.current = 0;
                        prepareInput(0);
                    }}
                >
                    <img src={arrow} alt={t("Back")} />
                </Link>
                <input
                    id="word"
                    type="text"
                    autoFocus={true}
                    placeholder={t("word")}
                    defaultValue={word.current.value}
                    ref={word}
                    onChange={changeWord}
                    onKeyDown={(e) => pressedKey(e)}
                    onFocus={handleClick}
                    maxLength={200}
                />
                <span className="divider"></span>
                <div id="meaning-container">
                    <div className="meaning-box" id="0">
                        <input
                            id="0"
                            className="meaning"
                            type="text"
                            placeholder={t("meaning")}
                            onKeyDown={(e) => pressedKey(e)}
                            onFocus={handleClick}
                            maxLength={200}
                        />
                        <button
                            className="plus"
                            id="0"
                            onClick={(e) => addNewMeaningInput(e)}
                        >
                            +
                        </button>
                        <button
                            className="minus"
                            id="0"
                            onClick={(e) => deleteMeaning(e)}
                        >
                            -
                        </button>
                    </div>
                </div>
                <span className="divider"></span>

                <select id="section" defaultValue={sec.current.value} ref={sec}>
                    {options}
                </select>
                {text}
                <div className="card-btn">
                    <Link to="/" className="add" onClick={addNewCard}>
                        {params.id ? t("Modify") : t("Add")}
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default NewCard;
