import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null
    );
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    function logoutUser() {
        localStorage.removeItem("authTokens");
        setAuthTokens(null);
        setUser(null);
        localStorage.setItem("category_index", 0);
        if (!loading) {
            window.location.reload();
        }
    }
    function setCredentials(data) {
        setAuthTokens(data);
        setUser(jwtDecode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        // navigate("/survey");
    }

    const googleAuth = async (credentialResponse) => {
        const response = await fetch("/api/social_login_google/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(credentialResponse),
        });
        const data = await response.json();
        if (response.status === 200) {
            setCredentials(data);
            console.log("google goo");
            console.log(data);
            // navigate("/survey");
        } else {
            setMessage(data.detail);
            console.log("google err");
            console.log(data);
        }
    };

    const responseFacebook = async (response) => {
        const res = await fetch("/api/social_login_fb/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(response),
        });
        const data = await res.json();
        if (res.status === 200) {
            setCredentials(data);
            console.log(data);
            console.log("fb goo");
            // navigate("/survey");
        } else {
            console.log(data);
            console.log("fb err");
            setMessage(data.detail);
        }
    };

    const loginUser = async (e, loadingFunc = () => {}) => {
        e.preventDefault();
        if (e.target.password.value === "" || e.target.email.value === "") {
            setMessage("Inputs can not be blank");
            loadingFunc("Inputs can not be blank");
        } else {
            const response = await fetch("/api/token/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: e.target.email.value,
                    password: e.target.password.value,
                }),
            });
            const data = await response.json();
            if (response.status === 200) {
                setCredentials(data);
                loadingFunc(message);
                // navigate("/survey");
            } else {
                setMessage(data.detail);
                loadingFunc(message);
            }
        }
    };

    async function updateToken() {
        const response = await fetch("/api/token/refresh/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                refresh: authTokens?.refresh,
            }),
        });
        const data = await response.json();
        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem("authTokens", JSON.stringify(data));
        } else {
            logoutUser();
        }
        if (loading) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (loading) {
            updateToken();
        }
        const twoHours = 1000 * 60 * 120;
        let interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, twoHours);
        return () => clearInterval(interval);
    }, [authTokens, loading]);

    const contextData = {
        user: user,
        loginUser: loginUser,
        logoutUser: logoutUser,
        authTokens: authTokens,
        message: message,
        responseFacebook: responseFacebook,
        googleAuth: googleAuth,
        setCredentials: setCredentials,
        setUser: setUser,
        setAuthTokens: setAuthTokens,
    };
    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
