import React, { useState, createContext } from "react";
import Header from "./components/Header";
import CardsPage from "./pages/CardsPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/NewPassword";
import PrivateRoute from "./utils/PrivateRoute";
import Pay from "./pages/Pay";
import UserIsLogin from "./utils/UserIsLogin";
import { CardsProvider } from "./context/CardsContext";
import { useTranslation } from "react-i18next";
import HelpPage from "./pages/HelpPage";
import { CurrentCategoryProvider } from "./context/CurrentCategoryContext";
import Footer from "./components/Footer";
import { AuthProvider } from "./context/AuthContext";
import UserFirstTime from "./utils/UserFirstTime";

import pl from "./photos/poland.png";
import en from "./photos/united-kingdom.png";
import Survey from "./pages/Survey";
import AccountPage from "./pages/AccountPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

const ThemeContext = createContext("dark");

function App() {
    const { i18n } = useTranslation();
    function languageSwitch(e) {
        const [curImg, curText] =
            document.querySelector(".dropdown-select").childNodes[0].childNodes;
        i18n.changeLanguage(e.target.id);
        switch (e.target.id) {
            case "pl":
                curImg.src = pl;
                curText.textContent = "Polski";
                break;
            default:
                curImg.src = en;
                curText.textContent = "English";
                break;
        }
    }

    const [mode, setMode] = useState(localStorage.getItem("theme") || "light");
    function changeTheme(e) {
        setMode((curr) => (curr === "light" ? "dark" : "light"));
    }
    localStorage.setItem("theme", mode);
    return (
        <ThemeContext.Provider value={{ mode, changeTheme }}>
            <div className="App" id={mode}>
                <BrowserRouter>
                    <div id="loading-page">
                        <h1>Loading ...</h1>
                    </div>
                    <AuthProvider>
                        <CardsProvider>
                            <CurrentCategoryProvider>
                                <Header
                                    lang={i18n.resolvedLanguage}
                                    changeLanguage={languageSwitch}
                                    changeTheme={changeTheme}
                                    mode={mode}
                                />

                                <main id="container">
                                    <Routes>
                                        <Route
                                            path="/privacy-policy"
                                            element={<PrivacyPolicy />}
                                        />
                                        <Route
                                            path="/terms-of-use"
                                            element={<TermsOfUse />}
                                        />
                                        <Route path="/pay" element={<Pay />} />
                                        <Route
                                            path="/account"
                                            element={<AccountPage />}
                                        />
                                        <Route
                                            path="/survey"
                                            element={<Survey />}
                                        />
                                        <Route
                                            path="/register"
                                            element={
                                                <UserIsLogin>
                                                    <RegisterPage />
                                                </UserIsLogin>
                                            }
                                        />
                                        <Route
                                            path="/reset_password/:pk/:token"
                                            element={
                                                <UserIsLogin>
                                                    <NewPassword />
                                                </UserIsLogin>
                                            }
                                        />
                                        <Route
                                            path="/reset_password"
                                            element={
                                                <UserIsLogin>
                                                    <ResetPassword />
                                                </UserIsLogin>
                                            }
                                        />
                                        <Route
                                            path="/login"
                                            element={
                                                <UserIsLogin>
                                                    <LoginPage />
                                                </UserIsLogin>
                                            }
                                        />
                                        <Route
                                            path="/help"
                                            element={<HelpPage />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <UserFirstTime>
                                                    <CardsPage />
                                                </UserFirstTime>
                                            }
                                        />
                                    </Routes>
                                    <Footer />
                                </main>
                            </CurrentCategoryProvider>
                        </CardsProvider>
                    </AuthProvider>
                </BrowserRouter>
            </div>
        </ThemeContext.Provider>
    );
}

export default App;
