import React from "react";
import panel from "../photos/bg-1.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const LandingPage = () => {
    const { t } = useTranslation();
    return (
        <div id="landing-page">
            <h1>{t("App created to improve your skills!")}</h1>
            <p>
                {t(
                    "Design your own style of learning and repeating your knowledge!"
                )}
            </p>
            <h2>
                {t("Start now and take a premium for free for new users")}{" "}
                <Link to="/register">{t("Try it")}</Link>
            </h2>
            <img src={panel} alt="" id="land-photo" />

            <h2>{t("How it works? ")}</h2>

            <p>
                {t(
                    "After login you can simply add your cards. You can write whatever you want. Then you can start learning these words or phrases simply by clicking on a \"Learn\" button. Then the lesson starts. You can modify your boxes and add more categories (what you want to learn for example english or german). You can't change the card's category, but it is possible to change its section. Thats all, app is simple and intuitive. Have fun with learning!"
                )}
            </p>
            <h2>{t("Is a access to application free? ")}</h2>

            <p className="p-100-bottom">
                {t(
                    "No. The access (premium account) is free only for new users for 30 days. Then it costs 20 PLN."
                )}
            </p>
        </div>
    );
};

export default LandingPage;
