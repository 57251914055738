import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const NewPassword = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    function loadingOn() {
        const inputs = document.querySelectorAll(`input`);
        inputs.forEach((element) => {
            element.classList.add("loading");
        });
        return inputs;
    }
    function loadingOff(arr) {
        arr.forEach((element) => {
            element.classList.remove("loading");
        });
    }

    async function sendNewPassword(e) {
        e.preventDefault();
        if (e.target.password.value !== "") {
            const inputs = loadingOn();
            setMessage("");
            const response = await fetch(
                `/api/reset_password/${params.pk}/${params.token}/`,
                {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ password: e.target.password.value }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                let count = 6;
                setMessage(
                    t(
                        `${data.data}, you will go to login page in ${count} seconds.`
                    )
                );
                const id = setInterval(() => {
                    count = count - 1;
                    setMessage(
                        t(
                            `${data.data}, you will go to login page in ${count} seconds.`
                        )
                    );
                    if (count === 0) {
                        clearInterval(id);
                        navigate("/login");
                    }
                }, 1000);
            } else {
                setMessage(data.password || data.non_field_errors);
            }
            loadingOff(inputs);
        }
    }
    return (
        <>
            <form onSubmit={(e) => sendNewPassword(e)}>
                <h3>{t("Type new password")}</h3>
                <input
                    id="reset-password"
                    type="password"
                    name="password"
                    placeholder={t("password")}
                />
                <p key="mes">{message}</p>
                <input type="submit" value={t("Submit")} />
            </form>
        </>
    );
};

export default NewPassword;
