import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const tl = gsap.timeline();
export function rotateCard() {
    const elements = document.querySelector(".visible").children;
    tl.to(elements, 0.1, { rotateY: 90, scale: 0.8 }).to(elements, 0.2, {
        rotateY: 0,
        scale: 1,
    });
}
