import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import CardsContext from "../context/CardsContext";
import Confirmation from "./Confirmation";
import CurrentCategoryContext from "../context/CurrentCategoryContext";
import arrow from "../photos/Arrow.svg";

const CategoryManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { authTokens } = useContext(AuthContext);
    const { getCategories, allCategories } = useContext(CardsContext);
    const { setCurrentCategory } = useContext(CurrentCategoryContext);
    const [message, setMessage] = useState("");

    const categories = allCategories?.map((e) => {
        return (
            <div key={e.id} className="category">
                <Confirmation id={e.id} func={() => deleteCategory(e.id)} />
                <input
                    type="text"
                    className="centered"
                    id={`input${e.id}`}
                    defaultValue={e.name}
                />
                <button
                    id={e.id}
                    onClick={() => {
                        document.querySelector(
                            `.confirmation${e.id}`
                        ).style.display = "grid";
                    }}
                >
                    {t("Delete")}
                </button>
                <button id={e.id} onClick={updateCategory}>
                    {t("Modify")}
                </button>
            </div>
        );
    });

    async function updateCategory(e) {
        const name = document
            .querySelector(`#input${e.target.id}`)
            .value.trim();
        if (allCategories.some((e) => e.name === name) || name === "") {
            return 0;
        }
        const data = {
            name: name,
        };
        const response = await fetch(`/api/category/${e.target.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify(data),
        });
        const recived = await response.json();
        if (response.status === 200) {
            getCategories();
        }
        setMessage(t(recived.data));
    }

    async function deleteCategory(id) {
        const name = document.querySelector(`#input${id}`).value;
        const data = {
            id: Number(id),
            name: name,
        };
        const response = await fetch(`/api/category/${id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify(data),
        });
        const recived = await response.json();
        if (response.status === 200) {
            getCategories();
            setCurrentCategory(allCategories?.at(0));
            localStorage.setItem("category_index", allCategories?.at(0));
        }
        setMessage(t(recived.data));
    }

    async function addCategory(e) {
        e.preventDefault();
        if (e.target.name.value.trim() === "") {
            return 0;
        }
        if (
            allCategories?.some(
                (category) => category.name === `${e.target.name.value}`
            )
        ) {
            setMessage(t("Section already exists"));
        } else {
            const data = {
                name: e.target.name.value,
            };
            const response = await fetch(`/api/categories/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(authTokens?.access),
                },
                body: JSON.stringify(data),
            });
            const recived = await response.json();
            if (response.status === 200) {
                getCategories();
            }
            setMessage(t(recived.data));
            e.target.name.value = "";
        }
    }

    function escPressed(e) {
        if (e.code === "Escape") {
            window.removeEventListener("keydown", escPressed);
            navigate("/");
        }
    }
    window.addEventListener("keydown", escPressed);

    return (
        <div className="visible">
            <div className="categories-box">
                <Link className="cancel back" onClick={() => navigate(-1)}>
                    <img src={arrow} alt={t("Back")} />
                </Link>
                <h2>{t("Your categories")}</h2>
                {categories}
                <p>{message}</p>
                <span className="divider"></span>
                <h2>{t("Create new category")}</h2>
                <form className="col" onSubmit={addCategory}>
                    <input
                        type="text"
                        className="centered"
                        name="name"
                        placeholder={t("name of new category")}
                    />
                    <button>{t("Add")}</button>
                </form>
            </div>
        </div>
    );
};

export default CategoryManagement;
