import React, { useContext, useState, useEffect } from "react";
import CardsContext from "../context/CardsContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import Confirmation from "./Confirmation";
import arrow from "../photos/Arrow.svg";

const SectionManagement = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { authTokens } = useContext(AuthContext);
    const { getCards, getSections } = useContext(CardsContext);

    const [message, setMessage] = useState("");
    let sectionHTMLElement;

    const sections = props.sections;
    const currentCategory = props.currentCategory;
    const sectionObjects = props.sectionObjects;
    const optionLabel = ["hours", "days", "months"];
    const hours = [];
    const days = [];
    const months = [];
    // prepare the labels for option HTML element
    for (let x = 1; x < 31; x++) {
        if (x < 13) {
            months.push(x);
        }
        if (x < 24) {
            hours.push(x);
        }
        days.push(x);
    }
    const HTMLoptionLabel = optionLabel.map((e) => {
        return (
            <option value={e} key={e}>
                {t(e)}
            </option>
        );
    });

    useEffect(() => {
        createDOM();
    }, [sectionObjects]);

    function onChangeLabel(e, i) {
        const select =
            document.querySelector(`.nr` + i) ||
            document.querySelector(".new-time");
        select.innerHTML = "";
        if (e.target.value === "hours") {
            hours.forEach((e) => {
                createOptions(select, e);
            });
        }
        if (e.target.value === "days") {
            days.forEach((e) => {
                createOptions(select, e);
            });
        }
        if (e.target.value === "months") {
            months.forEach((e) => {
                createOptions(select, e);
            });
        }
    }
    function createOptions(selectElement, element, arrObj = null) {
        const option = document.createElement("option");
        option.setAttribute("value", String(element));
        option.textContent = String(element);
        selectElement?.appendChild(option);
        if (selectElement && arrObj) {
            selectElement.value = String(Number.parseInt(arrObj.label));
        }
    }
    function createDOM() {
        sectionHTMLElement = sectionObjects?.map((arrObj, i) => {
            // set default value of the second select element
            function setDefault() {
                const select = document.querySelector(`.nr` + String(i));
                if (select) {
                    select.innerHTML = "";
                }
                if (arrObj.label.slice(-6) === "months") {
                    months.forEach((e) => {
                        createOptions(select, e, arrObj);
                    });
                    return "months";
                } else if (arrObj.label.slice(-5) === "hours") {
                    hours.forEach((e) => {
                        createOptions(select, e, arrObj);
                    });
                    return "hours";
                } else if (arrObj.label.slice(-4) === "days") {
                    days.forEach((e) => {
                        createOptions(select, e, arrObj);
                    });
                    return "days";
                }
            }
            return (
                <div
                    className={`one-section ${
                        i === 0 ||
                        i === sectionObjects.indexOf(sectionObjects.at(-1))
                            ? "unchangable"
                            : ""
                    }`}
                    id={`section-${i}`}
                    key={arrObj.id}
                >
                    <h3>{t(arrObj.label)}</h3>
                    {i === 0 ||
                    i === sectionObjects.indexOf(sectionObjects.at(-1)) ? (
                        <>
                            <div className="select-fields">
                                <p>{t("unchangable")}</p>
                            </div>
                            <div className="select-btn">
                                <button className={`section-btn ${i}`}>
                                    {t("Modify")}
                                </button>
                                <button>{t("Delete")}</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Confirmation
                                id={arrObj.id}
                                func={() => deleteSection(arrObj.id)}
                            />
                            <div className="select-fields">
                                <select className={"nr" + i}></select>
                                <select
                                    className={"nr" + i}
                                    defaultValue={setDefault()}
                                    onChange={(e) => {
                                        onChangeLabel(e, i);
                                    }}
                                >
                                    {HTMLoptionLabel}
                                </select>
                            </div>
                            <div className="select-btn">
                                <button
                                    className={`section-btn ${i}`}
                                    onClick={updateSection}
                                    id={arrObj.id}
                                >
                                    {t("Modify")}
                                </button>
                                <button
                                    className={`section-btn ${i}`}
                                    onClick={() => {
                                        document.querySelector(
                                            `.confirmation${arrObj.id}`
                                        ).style.display = "grid";
                                    }}
                                    id={arrObj.id}
                                >
                                    {t("Delete")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            );
        });
    }
    async function updateSection(e) {
        const id = e.target.className.split(" ").at(-1);
        const currSection = document.querySelector(`#section-${id}`);
        const [time, label] = document.querySelectorAll(`.nr${id}`);
        if (
            currSection.childNodes[0].textContent ===
            `${time.value} ${label.value}`
        ) {
            return 0;
        } else if (
            sectionObjects.some(
                (sec) => sec.label == `${time.value} ${label.value}`
            )
        ) {
            setMessage(t("Box already exists"));
            return 0;
        }
        let timeSeconds;
        if (label.value === "hours") {
            timeSeconds = Number(time.value) * 3600 * 1000;
        } else if (label.value === "days") {
            timeSeconds = Number(time.value) * 24 * 3600 * 1000;
        } else if (label.value === "months") {
            timeSeconds = Number(time.value) * (365 / 12) * 24 * 3600 * 1000;
        }
        const data = {
            id: Number(e.target.id),
            category: currentCategory.id,
            label: `${time.value} ${label.value}`,
            timer: String(Math.round(timeSeconds)),
        };
        const response = await fetch(`/api/section/${e.target.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify(data),
        });
        const recived = await response.json();
        if (response.status === 200) {
            getSections();
            getCards();
        }
        setMessage(t(recived.data));
    }

    async function deleteSection(id) {
        const data = {
            id: Number(id),
            category: currentCategory.id,
            newSection: sections[sections.indexOf(Number(id)) - 1],
        };
        const response = await fetch(`/api/section/${id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify(data),
        });
        const recived = await response.json();
        if (response.status === 200) {
            getSections();
            getCards();
        }
        setMessage(t(recived.data));
    }

    async function addSection(e) {
        e.preventDefault();
        const [time, label] = document.querySelectorAll(`.new`);
        let timeSeconds;
        if (label.value === "hours") {
            timeSeconds = Number(time.value) * 3600 * 1000;
        } else if (label.value === "days") {
            timeSeconds = Number(time.value) * 24 * 3600 * 1000;
        } else if (label.value === "months") {
            timeSeconds = Number(time.value) * (365 / 12) * 24 * 3600 * 1000;
        }
        if (
            sectionObjects.some(
                (e) => e.label === `${time.value} ${label.value}`
            )
        ) {
            setMessage(t("Box already exists"));
        } else {
            const data = {
                category: currentCategory.id,
                label: `${time.value} ${label.value}`,
                timer: String(Math.round(timeSeconds)),
            };

            const response = await fetch(`/api/sections/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(authTokens?.access),
                },
                body: JSON.stringify(data),
            });
            const recived = await response.json();
            if (response.status === 200) {
                getSections();
                getCards();
            }
            setMessage(t(recived.data));
        }
    }
    const options = hours.map((e) => {
        return (
            <option value={String(e)} key={e}>
                {e}
            </option>
        );
    });
    createDOM();

    function escPressed(e) {
        if (e.code === "Escape") {
            window.removeEventListener("keydown", escPressed);
            navigate("/");
        }
    }
    window.addEventListener("keydown", escPressed);

    return (
        <div className="visible">
            <div className="big-section-box">
                <Link className="cancel back" onClick={() => navigate(-1)}>
                    <img src={arrow} alt={t("Back")} />
                </Link>
                <h2>{t("Your boxes")}</h2>
                <div className="section-box">{sectionHTMLElement}</div>
                <p>{message}</p>
                <span className="divider"></span>
                <h2>{t("Add new box")}</h2>
                <p>{t("Time to repeat card")}</p>
                <form className="row" onSubmit={addSection}>
                    <select className="new new-time">{options}</select>
                    <select className="new" onChange={(e) => onChangeLabel(e)}>
                        {HTMLoptionLabel}
                    </select>
                    <button className="">{t("Add")}</button>
                </form>
            </div>
        </div>
    );
};

export default SectionManagement;
