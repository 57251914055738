import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="content-document">
            <h1>Polityka prywatności</h1>
            <p>
                Niniejsza Polityka Prywatności (“Polityka Prywatności”) jest
                częścią Regulaminu (“Regulamin”). Korzystając z naszych usług,
                akceptujesz zarówno nasz Regulamin, jak i Politykę Prywatności.
                Jeśli nie zgadzasz się na przetwarzanie przez nas Twoich danych
                osobowych prosimy o zaprzestanie korzystania z naszych Usług.
            </p>
            <h2>1. ADMINISTRATOR DANYCH OSOBOWYCH</h2>
            <p>
                Administratorem danych osobowych w rozumieniu rozporządzenia
                Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia 2016 r.
                w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                danych osobowych i w sprawie swobodnego przepływu takich danych
                oraz uchylenia dyrektywy 95/46/WE jest Krzesimir Makowski,
                mieszkający pod adresem: Dondajewskiego 26, 62-300 Września,
                Polska.
            </p>
            <h2>2. GROMADZENIE DANYCH</h2>
            <p>
                Aby móc dostarczyć Ci nasze usługi, przetwarzamy Twoje dane
                osobowe, które nam dobrowolnie i samodzielnie przekażesz. Będą
                to między innymi:
                <ul style={{ textAlign: "left" }}>
                    <li>
                        adres e-mail, nazwa użytkownika i zdjęcie profilowe;{" "}
                    </li>
                    <li>
                        {" "}
                        dane dotyczące Twojej nauki (kategorie, sekcje
                        (pudełka), karty (fiszki), których się uczysz, postępy;
                    </li>
                    <li>
                        informacje zawarte we kategoriach, sekcjach (pudełkach),
                        kartach (fiszkach) i innych publikowanych przez Ciebie
                        treściach;
                    </li>
                    <li>
                        odpowiedzi i informacje zawarte w ankietach, o których
                        wypełnienie możesz zostać poproszony;
                    </li>
                    <li>
                        dane podane przy płatności, czyli: numer karty, data jej
                        ważności oraz kod CVC, e-mail;
                    </li>
                    <li>
                        dane logowania, które są automatycznie wysyłane przez
                        Twoją przeglądarkę lub przez Twój telefon. Wśród tych
                        danych znajdzie się Twój adres IP, ustawienia językowe
                        (język systemu), data i godzina korzystania z naszych
                        usług, typ urządzenia, z którego się logujesz, system
                        operacyjny;
                    </li>
                    <li>pliki cookies;</li>
                </ul>
            </p>
            <h2>3. WYKORZYSTANIE DANYCH</h2>
            <p>
                Gromadzimy jedynie niezbędne dane, dzięki którym możemy
                zweryfikować Użytkownika, przetworzyć transakcję, dostarczyć
                nasze Usługi, a także je na bieżąco aktualizować i usprawniać.
                Zgromadzone dane ułatwią nam komunikację z Użytkownikiem i dadzą
                nam możliwość na spełnianie jego próśb i żądań.
                <br />
                Po wyrażeniu zgody na przesyłanie przez nas informacji
                handlowych drogą elektroniczną dane będą wykorzystywane w celach
                marketingowych. Dzięki temu będziemy mogli przesłać Ci
                informacje dotyczące promocji, specjalnych ofert lub innych
                wydarzeń, które mogą Cię zainteresować. W każdej chwili możesz
                zrezygnować z otrzymywania informacji handlowych, wysyłając
                wiadomość na nasz adres: mailbox@ycards.pl.
                <br />
                Nawet jeśli zrezygnujesz z otrzymywania informacji handlowych,
                będziesz dalej otrzymywać od nas wiadomości e-mail dotyczące
                ważności Twoich subskrypcji, zmian w dostarczaniu naszych Usług
                oraz w celu udzielania odpowiedzi na Twoje pytania.
            </p>
            <h2>4. POLITYKA PLIKÓW COOKIES</h2>
            <p>
                Niektóre obszary serwisów należących do Administratora mogą
                wykorzystywać cookies, czyli małe pliki tekstowe wysyłane do
                komputera internauty identyfikujące go w sposób potrzebny do
                uproszczenia lub umorzenia danej operacji. Cookies są
                nieszkodliwe ani dla danego urządzenia ani dla jego Użytkownika
                i jego danych.
                <br />
                Warunkiem działania cookies jest ich akceptacja przez
                przeglądarkę i nieusuwanie ich z dysku. Można też wyłączyć ich
                działanie w ustawieniach przeglądarki, co może ograniczyć albo
                uniemożliwić korzystanie z niektórych części serwisu. Pliki
                cookies wykorzystujemy w celach statystycznych, do autoryzacji
                użytkowników w serwisach, w celu dostosowania wyglądu serwisów
                do preferencji i historii przeglądania Użytkownika oraz w innych
                celach marketingowych.
                <br />
                Pliki cookies mogą zawierać dane osobowe użytkownika, chociaż w
                większości przypadków będzie to tylko adres e-mail. Dane te
                dostępne są tylko dla Użytkownika danego urządzenia jak i dla
                naszych serwisów.
            </p>
            <h2>5. JAWNE DANE</h2>
            <p>
                Dane osobowe, które podajesz na swoim Koncie w Aplikacji lub na
                naszej Stronie Internetowej, takie jak nazwa użytkownika lub
                zdjęcia, nie są dostępne dla innych użytkowników. Użytkownicy
                ponoszą odpowiedzialność za treści publikowane w domenach
                publicznych.
            </p>
            <h2>6. Kontakt</h2>
            <p>
                Zawsze możesz się z nami skontaktować za pomocą adresu:
                mailbox@ycards.pl
            </p>
        </div>
    );
};

export default PrivacyPolicy;
