import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useContext } from "react";
import setErrorPage from "../utils/errorPage";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const { user, authTokens, setCredentials } = useContext(AuthContext);

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe
            .retrievePaymentIntent(clientSecret)
            .then(async ({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");

                        const response = await fetch(`/api/premium/`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "Bearer " + String(authTokens?.access),
                            },
                            body: JSON.stringify({
                                user_id: user.user_id,
                                premium_expired_date_new:
                                    premium_expired_date_new,
                            }),
                        });
                        const data = await response.json();
                        if (response.status === 200) {
                            // console.log(data);
                            setCredentials(data);
                            window.location.replace(
                                `${window.location.origin}/success`
                            );
                        } else {
                            // console.log(data);
                            setErrorPage(data.error);
                        }

                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage(
                            "Your payment was not successful, please try again."
                        );
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            });
    }, [stripe]);
    const premium_expired_date_new = user.premium
        ? new Date(new Date(user.premium_expired_date).getTime() + 2592000000)
        : new Date(new Date().getTime() + 2592000000);

    // console.log(premium_expired_date_new);
    const freePremiumHandle = async (e) => {
        e.preventDefault();
        //apicall
        const response = await fetch(`/api/premium/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify({
                user_id: user.user_id,
                premium_expired_date_new: premium_expired_date_new,
            }),
        });
        const data = await response.json();
        if (response.status === 200) {
            setCredentials(data);
            navigate("/success");
        } else {
            // console.log(data);
            setErrorPage(data.error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location}`,
            },
        });
        //apicall
        if (!error) {
        }
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "accordion",
    };

    return (
        <div className="photo-and-form payment_form">
            {user.free_premium ? (
                <>
                    <div className="pay-form">
                        <h1>
                            {t("Premium for 30 days: ")}
                            <span style={{ textDecoration: "line-through" }}>
                                20PLN
                            </span>
                            <br></br>
                            {t("You have for free")}
                        </h1>
                        <h2>{t("Your account data:")}</h2>
                        <p>
                            {t("Name: ")} {user?.username}
                        </p>
                        <p>
                            {t("E-mail: ")} {user?.email}
                        </p>
                        {/* BUG - po polsku tekst poniżej */}
                        <p style={{ textAlign: "left", marginTop: "30px" }}>
                            {t(
                                "Wyrażasz zgodę na uzyskanie natychmiastowego dostępu do konta Ycards i potwierdzasz, że zwrot pieniędzy przysługuje Ci tylko w ciągu 14 dni. Warunki korzystania oraz instrukcja, jak zrezygnować z subskrypcji są dostępne tutaj."
                            )}
                        </p>
                    </div>
                    <form onSubmit={freePremiumHandle}>
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <h1>{t("Grab your free premium")}</h1>
                            <button>{t("Free premium for 30 days")}</button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <div className="pay-form">
                        <h1>
                            {t("Premium for 30 days: ")}
                            20PLN
                        </h1>
                        <h2>{t("Your account data:")}</h2>
                        <p>
                            {t("Name: ")} {user?.username}
                        </p>

                        <p>
                            {t("E-mail: ")} {user?.email}
                        </p>
                        {/* BUG - po polsku tekst poniżej */}
                        <p style={{ textAlign: "left", marginTop: "30px" }}>
                            {t(
                                "Wyrażasz zgodę na uzyskanie natychmiastowego dostępu do konta Ycards i potwierdzasz, że zwrot pieniędzy przysługuje Ci tylko w ciągu 14 dni. Warunki korzystania oraz instrukcja, jak zrezygnować z subskrypcji są dostępne "
                            )}
                            <Link
                                className="white-link"
                                to="/privacy-policy"
                                style={{
                                    display: "inline",
                                    textDecoration: "underline",
                                }}
                            >
                                {t("tutaj.")}
                            </Link>
                        </p>
                    </div>
                    <form id="payment-form" onSubmit={handleSubmit}>
                        <PaymentElement
                            id="payment-element"
                            options={paymentElementOptions}
                        />
                        <button
                            disabled={isLoading || !stripe || !elements}
                            id="submit"
                        >
                            <span id="button-text">
                                {isLoading ? (
                                    <div className="spinner" id="spinner"></div>
                                ) : (
                                    t("Pay now")
                                )}
                            </span>
                        </button>
                        {/* Show any error or success messages */}
                        {message && <div id="payment-message">{message}</div>}
                    </form>
                </>
            )}
        </div>
    );
}
