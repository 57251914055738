export default function setErrorPage(error = null) {
    const element = document.querySelector("#loading-page");
    element.style.display = "grid";
    element.children[0].textContent =
        error === null
            ? "Connection error, refresh the page."
            : `error: ${error}`;
    const button = document.createElement("button");
    button.innerText = "Refresh";
    button.setAttribute("id", "refresh-btn");
    button.addEventListener("click", () => {
        window.location.reload();
    });
    element.appendChild(button);
}
