import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import setErrorPage from "../utils/errorPage";
import bin from "../photos/recycle-bin.png";
import modify from "../photos/edit-button.png";
import bg from "../photos/wave-haikei1.svg";
import arrow from "../photos/Arrow.svg";
const AllCards = (props) => {
    const { t } = useTranslation();
    const { authTokens } = useContext(AuthContext);
    const cards = props.cards;
    const sections = props.sections;
    const timers = props.timers;
    const navigate = useNavigate();
    const [searchResult, setSearchResult] = useState(cards);
    const [timer, setTimer] = useState(0);
    let IntervalId = setInterval(() => {
        setTimer(timer + 1);
    }, 1000 * 60);
    // const date = new Date();
    // console.log(
    //     `${date.getFullYear()}-${String(date.getMonth()).padStart(
    //         2,
    //         0
    //     )}-${String(date.getDate()).padStart(2, 0)} ${String(
    //         date.getHours()
    //     ).padStart(2, 0)}:${String(date.getMinutes()).padStart(2, 0)}:${String(
    //         date.getSeconds()
    //     ).padStart(2, 0)}`
    // );

    // (card) - object for switching on loading and deleting the DOM if response is OK.
    async function deleteCard(card) {
        const elements = loadingOn(card);

        const response = await fetch(`/api/${card.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
        });
        if (response.status === 200) {
            const x = document.getElementById(card.id);
            x.classList.add("invisible");
            x.classList.remove("card");
            const delId = cards.findIndex((e) => {
                // console.log(card.id);
                if (e.id === card.id) {
                    return card;
                }
            });
            // console.log(delId);
            cards.splice(delId, 1);
            // console.log(cards);

            loadingOff(elements);
            // props.refresh();
        } else {
            setErrorPage();
        }
    }
    // preparing list of cards
    const listOfCards = sections?.map((section, i) => {
        let result = [];

        // display and prepare cards which can be needed for user
        const now = new Date();
        searchResult.forEach((card) => {
            let mes = t("active");
            const modified = new Date(card.modified_date.slice(0, 19));
            const delta = Math.floor(now - modified);
            if (section === card.section) {
                if (delta < timers[i]) {
                    const deltaDate = new Date(timers[i] - delta);
                    mes =
                        deltaDate.getTime() > 1000 * 3600 * 24
                            ? `${Math.round(
                                  deltaDate.getTime() / (1000 * 3600 * 24)
                              )} days`
                            : `${
                                  deltaDate.getHours()
                                      ? deltaDate.getHours() - 1
                                      : 23
                              } h ${deltaDate.getMinutes()} min`;
                }
                result.push(
                    <div key={card.id} id={card.id} className="card">
                        <button
                            className={`delete-btn${card.id}`}
                            onClick={() => deleteCard(card)}
                        >
                            <img src={bin} alt={t("DELETE")} className="icon" />
                        </button>
                        <div>{card.word}</div>
                        <div>
                            <p>{card.meanings.replaceAll("|", ", ")}</p>
                        </div>
                        <div>
                            <p>{i + 1}</p>
                        </div>
                        <div>
                            <p>{mes}</p>
                        </div>
                        <Link
                            className={`modify-btn${card.id}`}
                            to={`/modify/${card.section}/${card.id}`}
                        >
                            <img
                                src={modify}
                                alt={t("DELETE")}
                                className="icon"
                            />
                            {/* {t("MODIFY")} */}
                        </Link>
                    </div>
                );
            }
        });
        return result;
    });

    function loadingOn(card) {
        const delBtn = document.querySelector(`.delete-btn${card.id}`);
        const modBtn = document.querySelector(`.modify-btn${card.id}`);
        delBtn.classList.add("loading");
        modBtn.classList.add("loading");
        return [delBtn, modBtn];
    }
    function loadingOff(arr) {
        arr.forEach((element) => {
            element.classList.remove("loading");
        });
    }

    function escPressed(e) {
        if (e.code === "Escape") {
            window.removeEventListener("keydown", escPressed);
            clearInterval(IntervalId);
            navigate("/");
        }
    }
    window.addEventListener("keydown", escPressed);

    // prepare list of searching cards based on word and word's meanings, if user doesn't type anything add all cards
    const handleChange = (e) => {
        if (!e.target.value) return setSearchResult(cards);
        const resultArr = cards.filter(
            (card) =>
                card.word
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                card.meanings
                    .replaceAll("|", ", ")
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
        );
        setSearchResult(resultArr);
    };

    if (searchResult.length) {
        return (
            <div id="allCards" className="visible visible-all">
                <Link
                    className="back first-back"
                    to="/"
                    onClick={() => clearInterval(IntervalId)}
                >
                    <img src={arrow} alt={t("Back")} />
                </Link>
                <div>
                    <div className="card">
                        <p></p>
                        {/* <h2>{t("search")}:</h2> */}
                        {/* <img src={magnifier} alt="loupe" id="magnifier" /> */}
                        <input
                            id="search"
                            autoFocus={true}
                            type="text"
                            placeholder={t("search")}
                            onChange={(e) => handleChange(e)}
                        />
                        <img src={bg} alt="" id="bg-card" />
                    </div>
                    <div className="space">
                        <p></p>
                    </div>
                    <div className="card">
                        <div></div>
                        <div>
                            <p>{t("WORD")}</p>
                        </div>
                        <div>
                            <p>{t("MEANING")}</p>
                        </div>
                        <div>
                            <p>{t("BOX")}</p>
                        </div>
                        <div>
                            <p>{t("STATE")}</p>
                        </div>
                        <div></div>
                    </div>
                    {listOfCards}
                </div>
            </div>
        );
    } else {
        return (
            <div id="allCards" className="visible visible-all">
                <Link
                    className="first-back back "
                    to="/"
                    onClick={() => clearInterval(IntervalId)}
                >
                    <img src={arrow} alt={t("Back")} />
                </Link>
                <div>
                    <div className="card">
                        <p></p>
                        {/* <h2>{t("search")}:</h2> */}
                        {/* <img src={magnifier} alt="loupe" id="magnifier" /> */}
                        <input
                            id="search"
                            autoFocus={true}
                            type="text"
                            placeholder={t("search")}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="space">
                        <p></p>
                    </div>
                    <div className="card">
                        <div></div>
                        <div></div>
                        <div>
                            <h1>{t("No matching card")}</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AllCards;
