import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bg from "../photos/wave-haikei.svg";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer>
            <p>Y-CARDS &#169; 2024</p>
            <p></p>
            <Link className="white-link" to="/help">
                {t("Help")}
            </Link>
            <Link className="white-link" to="">
                {t("About Y-cards")}
            </Link>
            <img src={bg} alt="" className="bg-for-parent2" />
        </footer>
    );
};

export default Footer;
