import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
const UserIsLogin = ({ children }) => {
    const { user } = useContext(AuthContext);
    if (!user) return children;
    return <Navigate to={"/"} replace></Navigate>;
};

export default UserIsLogin;
