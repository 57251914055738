import React, { useRef, useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { rotateCard } from "../utils/animations";
import setErrorPage from "../utils/errorPage";
import arrow from "../photos/Arrow.svg";

// remember the component is generating when user clicked START or LEARN button (params.id is connected with that)
const Lesson = (props) => {
    const { t } = useTranslation();

    const [firstRender, setFirstRender] = useState(false);
    const { authTokens } = useContext(AuthContext);
    const cards = props.cards;
    const timers = props.timers;
    const sections = props.sections;
    const sectionParams = useParams().section;
    let userInput = useRef("");
    const lessonsCards = [];
    const reversed = Math.random() > 0.5; // determine if the meaning is switching with the word of the card
    const navigate = useNavigate();

    //NOTE necessary because if user reloads the page then primary cards array is blank,
    // BETTER  needed a function which remove Item when the page is reloaded (only then)
    // if (document.readyState === "complete" && localStorage.getItem("active")) {
    //     localStorage.removeItem("active");
    // }

    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
        return () => {
            window.removeEventListener("keydown", leave);
        };
    }, []);
    // prepare cards which are available for lesson
    // set an item in localStorage to prevent repeting this operation from being repeat
    // choosing the bahaviour is based on the params from the link
    if (!firstRender) {
        const now = new Date();
        localStorage.setItem("active", "true");
        switch (sectionParams) {
            case "all":
                sections.forEach((section, index) => {
                    cards.forEach((card) => {
                        const modified = new Date(
                            card.modified_date.slice(0, 19)
                        );
                        const timeDelta = now - modified;

                        if (
                            card.section === section &&
                            timeDelta >= timers[index]
                        ) {
                            lessonsCards.push(card);
                        }
                    });
                });
                break;
            default:
                cards.forEach((card) => {
                    const modified = new Date(card.modified_date.slice(0, 19));
                    const timeDelta = now - modified;
                    if (
                        card.section == sectionParams &&
                        timeDelta >= timers[sections.indexOf(card.section)]
                    ) {
                        lessonsCards.push(card);
                    }
                });
        }
        //Random lessons
        if (sectionParams != sections[0]) {
            for (let i = 0; i < lessonsCards.length; i++) {
                let randomIndex = Math.trunc(
                    Math.random() * (lessonsCards.length - 1)
                );
                [lessonsCards[i], lessonsCards[randomIndex]] = [
                    lessonsCards[randomIndex],
                    lessonsCards[i],
                ];
            }
        }
    }
    // console.log(String(new Date()).slice(4, 25));
    const primaryLessonArray = useRef(lessonsCards);
    let card = prepareCard(primaryLessonArray.current[0]);

    function changeUserInput(e) {
        userInput.current.value = e.target.value;
    }

    // moves card to the next or previous section
    // cardObject - current card, classToChange - class needed to manipulate the DOM,
    //move - move the card to the next section (1) or not (-1), arrOfLoadingElements - elements which needs to remove the loading class after all operations
    async function moveCard(
        cardObject,
        classToChange,
        move = 1,
        arrOfLoadingElements
    ) {
        // console.log(cardObject);
        const date = new Date();
        const data = {
            id: cardObject?.id,
            word: cardObject?.word,
            meanings: cardObject?.meanings,
            modified_date: `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, 0)}-${String(date.getDate()).padStart(2, 0)} ${String(
                date.getHours()
            ).padStart(2, 0)}:${String(date.getMinutes()).padStart(
                2,
                0
            )}:${String(date.getSeconds()).padStart(2, 0)}`,
            section:
                move === -1 && cardObject.section === sections[0]
                    ? cardObject.section
                    : sections[sections.indexOf(cardObject?.section) + move] ||
                      sections.at(-1),
        };
        // console.log(data);
        // console.log(cardObject);
        // cardObject.section = data.section;
        // console.log(cards);
        const response = await fetch(`/api/${cardObject.id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(authTokens?.access),
            },
            body: JSON.stringify(data),
        });
        if (response.status === 200) {
            cardObject.section = data.section;
            props.refresh();
            loadingOff(classToChange, arrOfLoadingElements);
            if (userInput.current) {
                userInput.current.value = "";
            }
            if (!primaryLessonArray.current.length) {
                window.addEventListener("keydown", leave);
            }
        } else if (response.status !== 200) {
            setErrorPage();
        }
    }

    function pressEnter(e, cardObj) {
        const status = document.getElementById("meaning-header");
        const check = document.getElementById("check");
        if (e.code === "Enter" && check.classList.value === "invisible") {
            if (status.className === "bad") {
                NextCard(cardObj, ".bad", -1);
            } else if (status.className === "good") {
                NextCard(cardObj, ".good");
            } else {
                NextCard(cardObj, ".again");
            }
        } else if (e.code === "Enter") {
            checkUserInput(e, cardObj);
        } else if (
            e.code === "Space" &&
            document.getElementById("next-bad").classList.value !==
                "invisible-bad"
        ) {
            NextCard(cardObj, ".bad");
        }
    }

    // prepare JSX for single card in the lesson
    function prepareCard(cardObject) {
        // console.log(cardObject?.meanings);
        return (
            <>
                {primaryLessonArray.current.length === 0 ? (
                    <>
                        <Link
                            className="back"
                            onClick={() => {
                                // console.log("presse");
                                // const body =
                                //     document.getElementsByTagName("body")[0];
                                // body.style.height = undefined;
                                // body.style.overflow = "scroll";
                                // console.log(body);
                                localStorage.removeItem("active");
                            }}
                            to="/"
                        >
                            <img src={arrow} alt={t("Back")} />
                        </Link>
                        <h1>{t("no more cards available")}</h1>
                    </>
                ) : (
                    <div className="lesson">
                        <Link
                            className="back"
                            onClick={() => {
                                localStorage.removeItem("active");
                                // console.log("presse");
                                // const body =
                                //     document.getElementsByTagName("body")[0];
                                // body.style.height = "";
                                // body.style.overflow = "scroll";
                                // console.log(body);
                            }}
                            to="/"
                        >
                            <img src={arrow} alt={t("Back")} />
                        </Link>
                        <p>
                            {t("available cards: ")}{" "}
                            {primaryLessonArray.current.length}
                        </p>
                        <h3>
                            {reversed
                                ? cardObject?.meanings.replaceAll("|", ", ")
                                : cardObject?.word}
                        </h3>
                        <h3 id="meaning-header" className="meanings">
                            {reversed
                                ? cardObject?.word
                                : cardObject?.meanings.replaceAll("|", ", ")}
                        </h3>
                        <input
                            id="meaning"
                            type="text"
                            placeholder={t("meaning")}
                            autoFocus={true}
                            ref={userInput}
                            defaultValue={userInput.current.value}
                            onChange={changeUserInput}
                            onKeyDown={(e) => pressEnter(e, cardObject)}
                            maxLength={200}
                        />

                        <Link
                            id="modify"
                            className="invisible"
                            to={`/modify/${cardObject.section}/${cardObject.id}`}
                        >
                            {t("edit it")}
                        </Link>
                        <button
                            id="check"
                            onClick={(e) => checkUserInput(e, cardObject)}
                            type="submit"
                        >
                            {t("Check")}
                        </button>
                        <span className="btn-desc">
                            <button
                                id="next-good"
                                className="invisible-good"
                                type="submit"
                                onClick={() => NextCard(cardObject, ".good")}
                            >
                                {t("Next")}
                            </button>
                        </span>
                        <span className="btn-desc">
                            <button
                                id="next-again"
                                className="invisible-again"
                                type="submit"
                                onClick={() => NextCard(cardObject, ".again")}
                            >
                                {t("Try again")}
                            </button>
                        </span>
                        <span className="btn-desc">
                            <button
                                id="next-bad"
                                type="submit"
                                className="invisible-bad"
                                onClick={() => NextCard(cardObject, ".bad", -1)}
                            >
                                {t("Next")}
                            </button>
                        </span>
                        <span className="btn-desc">
                            <button
                                className="invisible-bad"
                                onClick={() => NextCard(cardObject, ".bad")}
                            >
                                {" "}
                                {t(
                                    cardObject.section !== "7"
                                        ? "Correct (move to next section)"
                                        : "Correct"
                                )}
                            </button>
                        </span>
                    </div>
                )}
            </>
        );
    }

    // determine what to do with card
    function NextCard(cardObj, classToChange, moveToNextSection = 1) {
        const loading = loadingOn();
        if (
            (sectionParams !== "1" || moveToNextSection === 1) &&
            classToChange !== ".again"
        ) {
            moveCard(cardObj, classToChange, moveToNextSection, loading);
        } else {
            document.querySelector("#check").className = "";
            userInput.current.value = "";
            loadingOff(classToChange, loading);
        }
        document.querySelector(".blue-link").className = "invisible";
        if (classToChange === ".again") {
            primaryLessonArray.current.push(cardObj);
        }
        if (
            (sectionParams === "1" && moveToNextSection === -1) ||
            classToChange === ".again"
        ) {
            props.refresh();
        }
        document.querySelector("#meaning").focus();
        card = prepareCard(primaryLessonArray.current.shift());
    }

    function checkUserInput(e, cardObj) {
        const minOfMistakes = 2;
        e?.preventDefault();
        // console.log(cardObj.meanings.toLowerCase().split("|"));
        // console.log(userInput.current.value.toLowerCase().split("|"));
        // console.log(cardObj.word.toLowerCase().split("|"));
        if (!userInput.current.value) {
            console.log("The input doesn't like to be blank");
        } else if (!reversed) {
            rotateCard();
            // check if the user type a valid data, if not then count the mistakes, and then determine what to do (allows to try again or not)
            if (dataIsCorrect(cardObj.meanings)) {
                changeButtonsVisibility("good");
            } else {
                const mistakes = countMistakes(cardObj.meanings);

                if (mistakes <= minOfMistakes) {
                    changeButtonsVisibility("again");
                } else {
                    changeButtonsVisibility("bad");
                }
            }
        } else {
            rotateCard();
            // the same what above but when card is "reversed"
            if (dataIsCorrect(cardObj.word.replaceAll(",", "|"))) {
                changeButtonsVisibility("good");
            } else {
                const mistakes = countMistakes(
                    cardObj.word.replaceAll(", ", "|")
                );
                if (mistakes <= minOfMistakes) {
                    changeButtonsVisibility("again");
                } else {
                    changeButtonsVisibility("bad");
                }
            }
        }
    }

    function dataIsCorrect(pattern) {
        return (
            pattern
                .toLowerCase()
                .split("|")
                .includes(userInput.current.value.toLowerCase().trim()) ||
            pattern
                .toLowerCase()
                .split("|")
                .some(
                    (val) =>
                        userInput.current.value.trim().toLowerCase() ===
                        val.trim()
                )
        );
    }

    // argument: (type : string - words to check) returns (type number) the lower amount of mistakes for the best word
    function countMistakes(words) {
        let mistakes;
        let oldMistakes;
        const toCheck = userInput.current.value.toLowerCase().trim();
        // console.log(toCheck);
        words
            .toLowerCase()
            .split("|")
            .forEach((string) => {
                // compare each letter of the word

                const shorter =
                    string.length >= toCheck.length
                        ? toCheck.split("")
                        : string.split("");

                const longer =
                    string.length >= toCheck.length
                        ? string.split("")
                        : toCheck.split("");

                mistakes = longer.length - shorter.length;
                for (let [i, val] of shorter.entries()) {
                    if (val !== longer[i]) {
                        mistakes += 1;
                    }
                }
                mistakes = oldMistakes < mistakes ? oldMistakes : mistakes;
                oldMistakes = mistakes;
            });
        return mistakes;
    }

    function loadingOn() {
        const btns = document.querySelectorAll(`.button-visible`);
        const mod = document.querySelector(".modify");
        mod.classList.add("loading");
        btns.forEach((element) => {
            element.classList.add("loading");
        });
        return [...btns, mod];
    }
    function loadingOff(classToChange, arr) {
        arr.forEach((element) => {
            element.classList.remove("loading");
        });
        if (document.querySelector(classToChange)) {
            document.querySelector(classToChange).className = "meanings";
            const htmlElements = document.querySelectorAll(".btn-visible");
            for (let i = 0; i < htmlElements.length; i++) {
                htmlElements[i].className =
                    "invisible-" + classToChange.slice(1);
            }
            document.querySelector("#check").className = "";
        }
    }

    function changeButtonsVisibility(classToChange) {
        document.querySelector(".meanings").className = classToChange;
        const htmlElements = document.querySelectorAll(
            ".invisible-" + classToChange
        );
        for (let i = 0; i < htmlElements.length; i++) {
            htmlElements[i].className = "btn-visible";
        }
        document.querySelector("#check").className = "invisible";
        document.querySelector("#modify").className = "blue-link";
    }

    window.onpopstate = () => {
        localStorage.removeItem("active");
    };

    function leave(e) {
        // console.log(primaryLessonArray.current.length);
        if (e.code === "Escape") {
            // console.log("po");
            localStorage.removeItem("active");
            window.removeEventListener("keydown", leave);
            navigate("/");
        }
    }

    return (
        <div className="visible">
            <div className="modify">{card}</div>
        </div>
    );
};
export default Lesson;
