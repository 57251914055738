export function loadingOn(e = undefined) {
    e?.preventDefault();
    document.querySelector(".warning").textContent = "";
    const button = document.querySelector("#btn");
    button.classList.add("loading");
}
export function loadingOff(message) {
    const button = document.querySelector("#btn");
    button.classList.remove("loading");
    document.querySelector(".warning").textContent = message;
}
