import React, { useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import userImg from "../photos/user.png";
import m from "../photos/moon1.svg";
import s from "../photos/sun.svg";
import pl from "../photos/poland.png";
import en from "../photos/united-kingdom.png";
import CardsContext from "../context/CardsContext";
import CurrentCategoryContext from "../context/CurrentCategoryContext";
import gears from "../photos/settings.png";
import parrot from "../photos/sd.png";

const Header = ({ lang, changeLanguage, changeTheme }) => {
    let { user, logoutUser } = useContext(AuthContext);
    let { currentCategory, setCurrentCategory } = useContext(
        CurrentCategoryContext
    );
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { allCategories } = useContext(CardsContext);

    function ChangeCategory(e) {
        allCategories.forEach((element) => {
            if (Number(element.id) === Number(e.target.value)) {
                setCurrentCategory(element);
                localStorage.setItem(
                    "category_index",
                    allCategories?.indexOf(element)
                );
            }
        });
    }

    const options = currentCategory ? (
        allCategories.map((e, i) => {
            return (
                <option className="category" value={e.id} key={i}>
                    {e.name}
                </option>
            );
        })
    ) : (
        <></>
    );

    useEffect(() => {
        document.querySelector(".section-box")?.classList.add("light");
    }, []);
    function changeMenu() {
        const hamburger = document.querySelector("#hamburger");
        const navigation = document.querySelector("nav");
        hamburger.classList.toggle("hamburger-active");
        navigation.classList.toggle("active");
    }

    return (
        <header data-testid="header-id">
            <div
                onClick={() => {
                    navigate("/");
                }}
                id="logo"
            >
                <img src={parrot} id="logo-header" alt="" />
                Ycards
            </div>
            <button id="hamburger" onClick={changeMenu}>
                <span id="hamburger-box">
                    <span className="hamburger-line"></span>
                </span>
            </button>
            <nav>
                {!currentCategory ? (
                    <></>
                ) : (
                    <div id="category">
                        <p>{t("category: ")}</p>
                        <select
                            defaultValue={String(currentCategory?.id)}
                            onChange={ChangeCategory}
                        >
                            {options}
                        </select>
                        <Link to="/categories">
                            <img src={gears} alt="SET" />
                        </Link>
                    </div>
                )}
                <div className="dropdown-select">
                    <button className="current-option">
                        <img src={lang === "pl" ? pl : en} alt="EN" />
                        <p>{lang === "pl" ? "Polski" : "English"}</p>
                    </button>
                    <button
                        id="en"
                        className="dropdown-option"
                        onClick={changeLanguage}
                    >
                        <img id="en" src={en} alt="EN" />
                        <p id="en">English</p>
                    </button>
                    <button
                        id="pl"
                        className="dropdown-option"
                        onClick={changeLanguage}
                    >
                        <img id="pl" src={pl} alt="PL" />
                        <p id="pl">Polski</p>
                    </button>
                </div>

                <button onClick={changeTheme} id="switch-mode">
                    {localStorage.getItem("theme") === "dark" ? (
                        <img src={m} alt="DARK" />
                    ) : (
                        <img src={s} alt="LIGHT" />
                    )}
                </button>
                {user ? (
                    <div id="user">
                        <img src={userImg} alt="user" id="user_header" />

                        <div id="header_user_info" x>
                            <p>
                                {t("Name: ")}
                                {user.username}
                            </p>
                            <p>
                                {t("Email: ")}
                                {user.email}
                            </p>
                            <p>
                                {t("premium expired: ")}
                                {user.premium_expired_date.slice(0, 10)}
                            </p>
                            <Link
                                // className="white-link"
                                // style={{ textDecoration: "underline" }}
                                to="/account"
                            >
                                {t("Your profile")}
                            </Link>
                        </div>
                    </div>
                ) : (
                    <Link
                        className="blank-interior"
                        onClick={changeMenu}
                        to="/login"
                    >
                        {t("Login")}
                    </Link>
                )}
                {user ? (
                    ""
                ) : (
                    <Link
                        className="blank-interior"
                        onClick={changeMenu}
                        to="/register"
                    >
                        {t("Register")}
                    </Link>
                )}
            </nav>
        </header>
    );
};

export default Header;
